/* MarketingModule-container-parent-class */

:root {
    --white: #ffffff;
    --error-color: rgba(237, 0, 57, 0.5);
    --secondary-color: #006DF5;
    --primary-color: #6258FF;
    --text-primary-color: #1C1B1F;
    --text-secondary-color: #546376;
    --info-color: #A352C2;
    --info-light-color: #F9D8FF;
    --action-active-color: #949DB2;
    --gary-400-color: #BDBDBD;
    --gray-300-color: #E0E0E0;
    --gray-100-color: #F5F5F5;
    --success-main-color: #008945;
    --error-light-color: #FFDAD9;
    --error-dark-color: #940015;
    --primary-state-outline-border: rgba(98, 88, 255, 0.5);
    --primary-state-outline-hover: rgba(98, 88, 255, 0.08);
    --warnig-state-outline-border: rgba(242, 153, 74, 0.5);
    --action-selected: rgba(28, 27, 31, 0.08);
    --other-outline-border: rgba(148, 157, 178, 0.24);
    --primary-outline-border: rgba(98, 88, 255, 0.5);
    --secondary-outline-border: rgba(rgba(0, 109, 245, 0.5));
    --action-hover-color: rgba(98, 88, 255, 0.06);


    --h5-font-size: 24px;
    --h5-font-line-height: 30px;
    --h5-font-weight: 600;
    --h5-font-letter-spacing: 0.3px;


    --h6-font-size: 20px;
    --h6-font-line-height: 28px;
    --h6-font-weight: 600;
    --h6-font-letter-spacing: 0.15px;

    --button-medium-font-size: 14px;
    --button-medium-font-line-height: 24px;
    --button-medium-font-weight: 500;
    --button-medium-font-letter-spacing: 0.4px;

    --button-large-font-size: 15px;
    --button-large-font-line-height: 26px;
    --button-large-font-weight: 500;
    --button-large-font-letter-spacing: 0.46px;

    --table-header-font-size: 14px;
    --table-header-font-line-height: 24px;
    --table-header-font-weight: 500;
    --table-header-font-letter-spacing: 0.17px;


    --body2-font-size: 14px;
    --body2-font-line-height: 20px;
    --body2-regular-font-weight: 400;
    --body2-font-weight: 500;
    --body2-font-letter-spacing: 0.15px;

    --input-label-font-size: 12px;
    --input-label-font-line-height: 12px;
    --input-label-font-weight: 400;
    --input-label-font-letter-spacing: 0.15px;

    --input-text-font-size: 16px;
    --input-text-font-line-height: 24px;
    --input-text-font-weight: 400;
    --input-text-font-letter-spacing: 0.15px;

    --subtitle1-font-size: 18px;
    --subtitle1-font-line-height: 28px;
    --subtitle1-font-weight: 500;
    --subtitle1-font-letter-spacing: 0.15px;

    --chip2-font-size: 11px;
    --chip2-font-line-height: 16px;
    --chip2-font-weight: 400;
    --chip2-font-letter-spacing: 0.16px;

    --body1-font-size: 16px;
    --body1-font-line-height: 22px;
    --body1-font-weight: 500;
    --body1-font-letter-spacing: 0.15px;


    --helper-text-font-size: 12px;
    --helper-text-font-line-height: 14px;
    --helper-text-font-weight: 400;
    --helper-text-font-letter-spacing: 0.4px;



}


.MarketingModule-container-parent-class .marketing_list_v2_container input.input_focus_box_border_rem:focus {
    box-shadow: 0 0 0 rgba(0, 0, 0, 0.0) !important;
    border-bottom-color: var(--other-outline-border) !important;
}

.MarketingModule-container-parent-class .marketing_list_v2_container .mlvc_top_header_container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-bottom: 16px;
}

.MarketingModule-container-parent-class .mlvc_top_header_container .mlvc_thc_rs_header_title {
    margin: 0;
    font-size: var(--h5-font-size);
    font-weight: var(--h5-font-weight);
    line-height: var(--h5-font-line-height);
    letter-spacing: var(--h5-font-letter-spacing);
    color: var(--text-primary-color);
}

.MarketingModule-container-parent-class .mlvc_top_header_container .mlvc_thc_rs_header_sub_title {
    margin: 0;
    font-size: var(--body2-font-size);
    font-weight: var(--body2-font-weight);
    line-height: var(--body2-font-line-height);
    letter-spacing: var(--body2-font-letter-spacing);
    color: var(--text-secondary-color);
}

.MarketingModule-container-parent-class .mlvc_top_header_container .mlvc_thc_right_side {
    display: flex;
    align-items: center;
    grid-gap: 16px;

}

.MarketingModule-container-parent-class .mlvc_thc_right_side .meis_custom_button_container {
    font-size: var(--button-large-font-size);
    font-weight: var(--button-large-font-weight);
    line-height: var(--button-large-font-line-height);
    letter-spacing: var(--button-large-font-letter-spacing);
    color: var(--white) !important;
    padding: 8px 20px;
    display: flex;
    align-items: center;
    grid-gap: 5px;
    border-radius: 4px;
    cursor: pointer;
}

.MarketingModule-container-parent-class .mlvc_thc_right_side .meis_custom_button_container span {
    display: inline-flex;
}

/* folder body  start*/

/* folder body left side   */
.MarketingModule-container-parent-class .mlvc_body_container {
    display: flex;
    grid-gap: 16px;

}

.MarketingModule-container-parent-class .mlvc_body_container .mlvc_campaign_folder_container {
    width: 100%;
    max-width: 225px; 
    /* need to changed */
    background: var(--white);
    padding: 16px 0;
    border-radius: 8px;
    flex-shrink: 0;
    /* height: calc(100vh - 178px); if top header */
    height: calc(100vh - 111px);
    transition: width 0.5s;
    transition: max-width 0.5s;
}

.mlvc_campaign_folder_container .mlvc_campaign_folder_container_top {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 9px 4px 10px;
    font-size: var(--button-medium-font-size);
    font-weight: var(--button-medium-font-weight);
    line-height: var(--button-medium-font-line-height);
    letter-spacing: var(--button-medium-font-letter-spacing);
    color: var(--text-secondary-color);
}

.mlvc_campaign_folder_container .mlvc_campaign_folder_container_top span {
    display: inline-flex;
}

.mlvc_campaign_folder_container .mlvc_campaign_folder_inner::-webkit-scrollbar {
    width: 6px !important;
}

.mlvc_campaign_folder_container .mlvc_campaign_folder_inner {
    padding: 0 10px 8px 10px;
    /* height: calc(100vh - 308px); if top bar */
    height: calc(100vh - 257px);
    padding-right: 4px;
}

.mlvc_campaign_folder_container.cfvd_mv2w_container_drawer .mlvc_campaign_folder_inner {
    height: calc(100vh - 152px) !important;
}

.mlvc_campaign_folder_container .mlvc_campaign_folder_inner_single {
    display: flex;
    align-items: center;
    grid-gap: 6px;
    font-size: var(--button-medium-font-size);
    font-weight: var(--button-medium-font-weight);
    line-height: var(--button-medium-font-line-height);
    letter-spacing: var(--button-medium-font-letter-spacing);
    color: var(--text-primary-color);
    padding: 8px 4px;
    border-radius: 8px;
    cursor: pointer;
    justify-content: space-between;
    margin-right: 5px;
}

.mlvc_campaign_folder_container .mlvc_campaign_folder_inner_single.mlvc_campaign_folder_inner_single_active {
    background: var(--action-selected);
}

.mlvc_campaign_folder_container .mlvc_campaign_folder_inner_single:hover {
    background: var(--action-selected);
}

.mlvc_campaign_folder_container .mlvc_campaign_folder_inner_single span {
    display: inline-flex;
}

.mlvc_campaign_folder_container .mlvc_campaign_folder_create {
    display: flex;
    align-items: center;
    grid-gap: 10px;
    font-size: var(--button-medium-font-size);
    font-weight: var(--button-medium-font-weight);
    line-height: var(--button-medium-font-line-height);
    letter-spacing: var(--button-medium-font-letter-spacing);
    color: var(--text-secondary-color);
    cursor: pointer;
    padding-top: 8px;
}

.mlvc_campaign_folder_container .mlvc_campaign_new_camp_create {
    display: flex;
    align-items: center;
    justify-content: center;
    grid-gap: 10px;
    font-size: var(--button-medium-font-size);
    font-weight: var(--button-medium-font-weight);
    line-height: var(--button-medium-font-line-height);
    letter-spacing: var(--button-medium-font-letter-spacing);
    background: var(--secondary-color);
    color: var(--white);
    cursor: pointer;
    padding: 8px;
    justify-content: center;
    border-radius: 4px;
    margin: 0 8px 8px 8px;
}

.mlvc_campaign_folder_container .mlvc_campaign_new_camp_create span {
    display: inline-flex;
}

.mlvc_campaign_folder_container .mlvc_campaign_new_camp_create span svg path {
    fill: var(--white)
}


.mlvc_campaign_folder_container .mlvc_campaign_folder_create span {
    display: inline-flex;
}

.mlvc_campaign_folder_container .mlvc_campaign_folder_inner_single {
    margin-bottom: 1px;
}

.mlvc_campaign_folder_container .mlvc_campaign_folder_inner_single .mlvc_campaign_folder_inner_single_row {
    display: flex;
    align-items: center;
    grid-gap: 6px;
    overflow: hidden;

}

.mlvc_campaign_folder_container .mlvc_campaign_folder_inner_single .mlvc_campaign_folder_inner_single_row span {
    display: inline-flex;

}

.MarketingModule-container-parent-class .mlvc_campaign_folder_inner_single .mlvc_campaign_folder_is_title {
    word-break: break-word;
    /* white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden; */
    display: block !important;
}

.mlvc_campaign_folder_container .mlvc_campaign_folder_inner_single .mlvc_campaign_folder_inner_single_menu {
    display: none;
    background: var(--gray-6);
    height: 22px;
    width: 22px;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    flex-shrink: 0;
}

.mlvc_campaign_folder_container .mlvc_campaign_folder_inner_single_menu .new_folder_c_menu_list {
    display: flex;
}

.mlvc_campaign_folder_container .mlvc_campaign_folder_inner_single_menu .new_f_c_over_menu_item_label_default {
    display: flex;
    align-items: center;
    justify-self: center;
}

.mlvc_campaign_folder_container .mlvc_campaign_folder_inner_single .pype_custom_menu_container,
.mlvc_campaign_folder_container .mlvc_campaign_folder_inner_single:hover .mlvc_campaign_folder_inner_single_menu {
    display: inline-flex;
}

.new_folder_c_over_container.awesome__scroll_bar {
    padding: 11px 10px;
}

.new_folder_c_over_container .new_f_c_over_menu_item {
    display: flex;
    grid-gap: 8px;
    font-size: 14px;
    color: var(--text-secondary-color);
    padding: 5px 2px;
    margin-bottom: 2px;
    border-radius: 4px;
    align-items: center;
    cursor: pointer;
}

.new_folder_c_over_container .new_f_c_over_menu_item:hover {
    background: var(--action-selected);
}

.new_folder_c_over_container .new_f_c_over_menu_item span {
    display: inline-flex;
}




/* folder body right side start  */


.MarketingModule-container-parent-class .empty_folder_search_wrpper_v2 {
    display: flex;
    padding: 0 8px;
    grid-gap: 12px;
    justify-content: flex-end;
}

.MarketingModule-container-parent-class .empty_folder_search_wrpper_v2 .empty_folder_search_inner_v2 {
    width: 100%;
    max-width: 350px;
    position: relative;
}

.MarketingModule-container-parent-class .empty_folder_search_wrpper_v2 .empty_folder_search_inner_input_v2 {
    border: 1px solid var(--other-outline-border);
    border-radius: 4px;
    height: 38px;
    padding: 10px 10px 10px 33px;
    margin: 0;
}

.MarketingModule-container-parent-class .empty_folder_search_wrpper_v2 .empty_folder_search_inner_input_v2:focus {
    border-bottom-color: var(--other-outline-border) !important;
    box-shadow: 0 0 0 rgba(0, 0, 0, 0.0) !important;
}

.MarketingModule-container-parent-class .empty_folder_search_wrpper_v2 .empty_folder_search_inner_icon_v2 {
    position: absolute;
    left: 8px;
    top: 9px;
}

.MarketingModule-container-parent-class .mlvc_body_container .mlvc_bcmbf_empty,
.MarketingModule-container-parent-class .mlvc_body_container .mlvc_bcr_c_top_b_empty {
    display: none !important;
}


.MarketingModule-container-parent-class .mlvc_body_container .mlvc_bcr_empty_row {
    display: flex;
    align-items: center;
    justify-content: center;
    height: calc(100vh - 213px);
    text-align: center;
    flex-direction: column;
}

.MarketingModule-container-parent-class .mlvc_body_container .mlvc_bcr_empty_container_revers {
    display: none;
}

.MarketingModule-container-parent-class .mlvc_body_container .mlvc_bcr_empty_img svg {
    height: 100%;
}


.MarketingModule-container-parent-class .mlvc_body_container .mlvc_bcr_empty_rc_title {
    font-size: var(--h5-font-size);
    font-weight: var(--h5-font-weight);
    line-height: var(--h5-font-line-height);
    letter-spacing: var(--h5-font-letter-spacing);
    color: var(--text-primary-color);
    margin: 8px 0 2px 0;
}

.MarketingModule-container-parent-class .mlvc_body_container .mlvc_bcr_empty_rc_subtitle {
    font-size: var(--body1-font-size);
    font-weight: var(--body1-font-weight);
    line-height: var(--body1-font-line-height);
    letter-spacing: var(--body1-font-letter-spacing);
    color: var(--text-secondary-color);

}

/* folder right side empty css end */
.MarketingModule-container-parent-class .mlvc_body_container .mlvc_body_container_right {
    background: var(--white);
    padding: 16px 10px;
    border-radius: 8px;
    flex: 2;
}

.MarketingModule-container-parent-class .marketing_list_v2_container .mlvc_body_container_top_serch {
    height: 38px;
}

.MarketingModule-container-parent-class .mlvc_body_container_middle_bottom_not_fixed .mlvc_body_container_middle {
    /* height: calc(100vh - 253px) !important; if top header */
    height: calc(100vh - 182px) !important;
    padding: 0;
}

.MarketingModule-container-parent-class .mlvc_body_container_middle_bottom_fixed .mlvc_body_container_middle {
    /* height: calc(100vh - 299px) !important; if top header   */
    height: calc(100vh - 232px) !important;
    padding: 0 0 8px 0;
}

.MarketingModule-container-parent-class .marketing_list_v2_container .mlvc_body_container_middle_bottom_fixed .mlvc_ecb_sc_pagination {
    border-top: 1px solid var(--other-outline-border);
    margin: 0px 13px 0 13px;
    padding-top: 10px;
    position: relative;
}

.MarketingModule-container-parent-class .marketing_list_v2_container .mlvc_body_container_middle::-webkit-scrollbar {
    width: 6px !important;
}

.MarketingModule-container-parent-class .marketing_list_v2_container .mlvc_each_campaign_body_wrapper {
    display: flex;
    align-items: flex-start;
    position: relative;
}

.MarketingModule-container-parent-class .marketing_list_v2_container .mlvc_each_campaign_body_wrapper .mlvc_ecbw_block_loader {
    position: absolute;
}

.MarketingModule-container-parent-class .marketing_list_v2_container .mlvc_each_campaign_body_wrapper+.mlvc_each_campaign_body_wrapper {
    margin: 24px 0 0 0;

}

.MarketingModule-container-parent-class .marketing_list_v2_container .mlvc_each_campaign_bodyw_check_box {
    flex-shrink: 0;
}

.MarketingModule-container-parent-class .marketing_list_v2_container .mlvc_each_campaign_body {
    padding: 20px 32px;
    border: 1px solid var(--other-outline-border);
    border-radius: 8px;
    flex: 1;
    transition: all linear 0.3s;
}

.MarketingModule-container-parent-class .marketing_list_v2_container .mlvc_each_campaign_body_active .mlvc_ecb_top_bar {
    padding-bottom: 0;
    margin-bottom: 0;
    border-bottom: 0;
    transition: all linear 0.3s;
}

.MarketingModule-container-parent-class .marketing_list_v2_container .mlvc_each_campaign_body_active .mlvc_ecb_stats_container {
    height: 0;
    z-index: 0;
    opacity: 0;
    transition: all linear 0.3s;
}

.mlvc_campaign_folder_container .mlvc_campaign_crete_bottom_btn {
    border-top: 1px solid #ddd;
    text-align: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 5px 0 10px 10px;
    grid-gap: 3px;
}


/* .MarketingModule-container-parent-class .marketing_list_v2_container .mlvc_campaign_crete_bottom_btn{
    display: flex;
    justify-content: space-between;
}
.MarketingModule-container-parent-class .mlvc_campaign_crete_bottom_btn .meis_custom_button_container{
    font-size: 12px;
    padding: 4px 2px;
}
.MarketingModule-container-parent-class .mlvc_campaign_crete_bottom_btn .meis_custom_button_container svg{
    width:16px;
} */




/* campaign folder start */
.add_campaign_folder_mv2_wr {
    padding: 24px 0 0 0 !important;
}

.add_campaign_folder_modal_v2 .add_campaign_fm_header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 14px 24px 14px;
    border-bottom: 1px solid #ddd;
    margin-bottom: 24px;
}

.add_campaign_folder_modal_v2 .add_campaign_fm_header .add_campaign_fm_title {
    font-size: var(--h5-font-size);
    font-weight: var(--h5-font-weight);
    line-height: var(--h5-font-line-height);
    letter-spacing: var(--h5-font-letter-spacing);
    color: var(--text-primary-color);
    margin: 0;
}

.add_campaign_folder_modal_v2 .add_campaign_fm_header .add_campaign_fm_icon {
    height: 30px;
    width: 30px;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    background: var(--gray-100-color);
    border-radius: 50%;
    cursor: pointer;
}

.add_campaign_folder_modal_v2 .add_campaign_fm_body {
    padding: 0 24px 17px 24px;
    height: calc(100vh - 186px);
}

.add_campaign_folder_modal_v2 .add_campaign_fm_body::-webkit-scrollbar {
    width: 6px;
}

.add_campaign_folder_modal_v2 .add_campaign_fm_body .add_campaign_fm_body_form_group+.add_campaign_fm_body_form_group {
    padding: 24px 0 0 0;
}

.add_campaign_folder_modal_v2 .add_campaign_fm_body .add_campaign_fm_body_form_group_label {
    font-size: var(--body2-font-size);
    font-weight: var(--body2-font-weight);
    line-height: var(--body2-font-line-height);
    letter-spacing: var(--body2-font-letter-spacing);
    color: var(--text-primary-color);
    margin: 0 0 4px 0;
}

.add_campaign_folder_modal_v2 .add_campaign_fm_body .add_campaign_fm_body_from_group_input {
    height: 48px;
    border: 1px solid var(--other-outline-border);
    border-radius: 4px;
    margin: 0;
    font-size: var(--input-text-font-size);
    font-weight: var(--input-text-font-weight);
    line-height: var(--input-text-font-line-height);
    letter-spacing: var(--input-text-font-letter-spacing);
    color: var(--text-primary-color);
    padding: 12px;
}

.add_campaign_folder_modal_v2 .add_campaign_fm_body .add_campaign_fm_body_from_group_input:focus {
    box-shadow: 0 0 0 rgba(0, 0, 0, 0.0);
    border-bottom-color: var(--other-outline-border);
}

.add_campaign_folder_modal_v2 .add_campaign_fm_body .add_campaign_fm_body_from_group_textarea {
    height: 68px;
    border: 1px solid var(--other-outline-border);
    border-radius: 4px;
    margin: 0;
    resize: none;
    padding: 12px;
    font-size: var(--input-text-font-size);
    font-weight: var(--input-text-font-weight);
    line-height: var(--input-text-font-line-height);
    letter-spacing: var(--input-text-font-letter-spacing);
    color: var(--text-primary-color);
}

.add_campaign_folder_modal_v2 .add_campaign_fm_body .add_campaign_fm_body_from_group_textarea:focus {
    outline: none;
}

.add_campaign_folder_modal_v2 .add_campaign_fm_body .add_campaign_fm_body_ic {
    display: grid;
    grid-template-columns: 6fr 6fr;
    grid-gap: 24px;
    padding-top: 14px;
}

.add_campaign_folder_modal_v2 .add_campaign_fm_body .add_campaign_fm_body_ic_error_msg {
    font-size: 14px;
    display: block;
    color: var(--red);
    margin-top: 36px;
}

.add_campaign_folder_modal_v2 .add_campaign_fm_body_ic_left .add_campaign_fm_ic_title {
    font-size: var(--body2-font-size);
    font-weight: var(--body2-font-weight);
    line-height: var(--body2-font-line-height);
    letter-spacing: var(--body2-font-letter-spacing);
    color: var(--text-primary-color);
    margin: 0 0 4px 0;
    display: block;
}

.add_campaign_folder_modal_v2 .add_campaign_fm_body .add_campaign_fm_ic_left_inner {
    border: 1px solid var(--other-outline-border);
    border-radius: 4px;
    padding: 16px;
    height: 100%;
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(30px, 1fr));
    grid-column-gap: 10px;
    grid-row-gap: 3px;
    justify-content: center;
    align-content: flex-start;

}

.add_campaign_folder_modal_v2 .add_campaign_fm_body .add_campaign_fm_ic_left_inner .cfamw_b_icpw_iw_icons_item {
    border: 1px solid rgba(0, 0, 0, 0.0);
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 4px;
    cursor: pointer;
}

.add_campaign_folder_modal_v2 .add_campaign_fm_body .add_campaign_fm_ic_left_inner .active_selectd_icon {
    border-color: var(--secondary-color) !important;
}

.add_campaign_folder_modal_v2 .add_campaign_fm_body .add_campaign_fm_ic_right_inner {
    border: 1px solid var(--other-outline-border);
    border-radius: 4px;
    padding: 16px;
    height: 100%;
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(45px, 1fr));
    grid-gap: 12px;
    grid-column-gap: 10px;
    grid-row-gap: 3px;
    align-content: flex-start;


}

.add_campaign_folder_modal_v2 .add_campaign_fm_ic_right_inner .add_campaign_fm_single_color {
    height: 32px;
    background: #ddd;
    border-radius: 4px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    border: 1px solid var(--other-outline-border);
}




.add_campaign_folder_modal_v2 .add_campaign_fm_footer {
    display: flex;
    justify-content: space-between;
    border-top: 1px solid #ddd;
    padding: 20px 24px 0 24px;
    align-items: center;
}

.add_campaign_folder_modal_v2 .add_campaign_fm_delete_btn {
    display: flex;
    align-items: center;
    background: var(--error-light-color);
    color: var(--error-dark-color);
    border-radius: 4px;
    font-size: var(--button-large-font-size);
    font-weight: var(--button-large-font-weight);
    line-height: var(--button-large-font-line-height);
    letter-spacing: var(--button-large-font-letter-spacing);
    padding: 8px 6px;
    grid-gap: 10px;
    cursor: pointer;
}

.add_campaign_folder_modal_v2 .add_campaign_fm_footer_right {
    display: flex;
    align-items: center;
    grid-gap: 32px;
}

.add_campaign_folder_modal_v2 .add_campaign_fm_cancel_btn {
    font-size: var(--button-large-font-size);
    font-weight: var(--button-large-font-weight);
    line-height: var(--button-large-font-line-height);
    letter-spacing: var(--button-large-font-letter-spacing);
    color: var(--text-secondary-color);
    cursor: pointer;
}

.add_campaign_folder_modal_v2 .add_campaign_fm_add_btn {
    display: flex;
    align-items: center;
    background: var(--success-main-color);
    border-radius: 4px;
    font-size: var(--button-large-font-size);
    font-weight: var(--button-large-font-weight);
    line-height: var(--button-large-font-line-height);
    letter-spacing: var(--button-large-font-letter-spacing);
    color: var(--white);
    padding: 8px 6px;
    grid-gap: 10px;
    cursor: pointer;

}

.add_campaign_folder_modal_v2 .add_campaign_fm_delete_btn span,
.add_campaign_folder_modal_v2 .add_campaign_fm_add_btn span {
    display: inline-flex;
}

/* campaign folder end */


/* pagination design start */
.MarketingModule-container-parent-class .marketing_list_v2_container .campaign_list_perpage_body {
    border: 1px solid var(--other-outline-border);
    border-radius: 4px;
    display: flex;
    align-items: center;
    padding: 3px 10px;
    max-width: 80px;
    width: 100%;
    grid-gap: 26px;
    cursor: pointer;
}

.MarketingModule-container-parent-class .marketing_list_v2_container  .clpb_icon {
    display: inline-flex;
    margin-top: 3px !important;
}

/* pagination design end */

.MarketingModule-container-parent-class .marketing_list_v2_container .campaign_status_filter_new {
    border: 1px solid var(--other-outline-border);
    border-radius: 4px;
    display: flex;
    align-items: center;
    padding: 3px 10px;
    max-width: 280px;
    width: 100%;
    grid-gap: 26px;
    cursor: pointer;
}

.new_folder_c_over_container.awesome__scroll_bar .new_f_c_over_menu_item.campaign_list_dropdown:hover{
	background-color: #cbd4ff !important;
}
.new_folder_c_over_container.awesome__scroll_bar .new_f_c_over_menu_item.campaign_list_dropdown{
	font-size: 15px !important; 
    color: #133159 !important;
    font-weight: 400 !important;
}

/* add folder fade modal design start */
.add_new_folder_fade_modal_v2_wr {
    max-width: 500px;
    background: #fff;
    width: 100%;
    border-radius: 4px;
}

.add_new_folder_fade_modal_v2_wr:focus {
    outline: none;
}

.add_new_folder_fade_modal_v2_body .MuiSvgIcon-root {
    color: var(--text-secondary-color) !important;
}

.add_new_folder_fade_modal_v2_body .MuiButtonBase-root.Mui-checked .MuiSvgIcon-root {
    color: var(--primary-color) !important;
}

.add_new_folder_fade_modal_v2_wr .add_new_folder_fade_modal_v2_header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-bottom: 2px solid #ddd;
    margin-bottom: 24px;
    padding: 18px 20px 18px 18px;
    background: rgba(250, 250, 250, 1);
    border-top-right-radius: 4px;
    border-top-left-radius: 4px;

}

.add_new_folder_fade_modal_v2_wr .add_new_folder_fade_modal_v2_header_left {
    display: flex;
    grid-gap: 8px;

}

.add_new_folder_fade_modal_v2_wr .add_new_folder_fade_modal_v2_header_close {
    height: 30px;
    width: 30px;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    background: var(--error-light-color);
    border-radius: 50%;
    cursor: pointer;
}

.add_new_folder_fade_modal_v2_wr .add_new_folder_fade_modal_v2_header_close svg path {
    fill: var(--error-color)
}

.add_new_folder_fade_modal_v2_wr .add_new_folder_fade_modal_v2_body {
    padding: 0 16px 24px 16px;
    max-height: calc(100vh - 300px);
    overflow-y: auto;
}

.add_new_folder_fade_modal_v2_wr .add_new_folder_fade_modal_v2_body .MuiFormControl-root {
    padding-left: 10px;
}

.add_new_folder_fade_modal_v2_wr .add_new_folder_fade_modal_v2_body .add_new_folder_fade_modal_v2_content_title {
    font-size: var(--body2-font-size);
    font-weight: var(--body2-font-weight);
    line-height: var(--body2-font-line-height);
    letter-spacing: var(--body2-font-letter-spacing);
    color: var(--text-primary-color);
    margin: 0 0 12px 0;
}

.add_new_folder_fade_modal_v2_wr .add_new_folder_fade_modal_v2_footer {
    display: flex;
    justify-content: flex-end;
    border-top: 1px solid var(--other-outline-border);
    grid-gap: 32px;
    padding: 20px 24px 24px 24px;
    align-items: center;
}

.add_new_folder_fade_modal_v2_wr .add_new_folder_fade_modal_v2_footer_cancel {
    font-size: var(--button-large-font-size);
    font-weight: var(--button-large-font-weight);
    line-height: var(--button-large-font-line-height);
    letter-spacing: var(--button-large-font-letter-spacing);
    color: var(--text-secondary-color);
    cursor: pointer;
}

.add_new_folder_fade_modal_v2_wr .add_new_folder_fade_modal_v2_footer_confirl {
    background: var(--success-main-color);
    border-radius: 4px;
    padding: 8px 16px;
    cursor: pointer;
    font-size: var(--button-large-font-size);
    font-weight: var(--button-large-font-weight);
    line-height: var(--button-large-font-line-height);
    letter-spacing: var(--button-large-font-letter-spacing);
    color: var(--white);
}


/* Action Required Modal Strt */
.action_required_fade_modal_v2_wr {
    max-width: 785px;
    background: #fff;
    width: 100%;
    border-radius: 4px;
}

.action_required_fade_modal_v2_wr:focus {
    outline: none;
}

.action_required_fade_modal_v2_body .MuiSvgIcon-root {
    color: var(--text-secondary-color) !important;
}

.action_required_fade_modal_v2_body .MuiButtonBase-root.Mui-checked .MuiSvgIcon-root {
    color: var(--primary-color) !important;
}

.action_required_fade_modal_v2_wr .action_required_fade_modal_v2_header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-bottom: 2px solid #ddd;
    margin-bottom: 24px;
    padding: 18px 20px 18px 18px;
    background: rgba(250, 250, 250, 1);
    border-top-right-radius: 4px;
    border-top-left-radius: 4px;

}

.action_required_fade_modal_v2_wr .action_required_fade_modal_v2_header_left {
    display: flex;
    grid-gap: 8px;

}

.action_required_fade_modal_v2_wr .action_required_fade_modal_v2_header_close {
    height: 30px;
    width: 30px;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    background: var(--error-light-color);
    border-radius: 50%;
    cursor: pointer;
}

.action_required_fade_modal_v2_wr .action_required_fade_modal_v2_header_close svg path {
    fill: var(--error-color)
}

.action_required_fade_modal_v2_wr .action_required_fade_modal_v2_body {
    display: grid;
    grid-template-columns: 7fr 5fr;
    height: 450px;
    padding: 20px;
}

.action_required_fade_modal_v2_wr .action_required_fade_mvb_title {
    font-size: var(--subtitle1-font-size);
    font-weight: var(--subtitle1-font-weight);
    letter-spacing: var(--subtitle1-font-letter-spacing);
    line-height: var(--subtitle1-font-line-height);
    color: var(--text-primary-color);
    margin: 0;
}

.action_required_fade_modal_v2_wr .action_required_fade_mvb_progress {
    background: var(--action-hover-color);
    padding: 20px;
    border-radius: 4px;
    display: inline-block;
    margin: 28px 0 20px 0;
    width: 100%;
    max-width: 280px;
}

.action_required_fade_modal_v2_wr .action_required_fade_mvb_progress .action_required_fade_mvb_progress_title {
    font-size: var(--body2-font-size);
    font-weight: var(--body2-font-weight);
    letter-spacing: var(--body2-font-letter-spacing);
    line-height: var(--body2-font-line-height);
    color: var(--text-secondary-color);
}

.action_required_fade_modal_v2_wr .action_required_fade_progress_line_wr {
    display: flex;
    justify-content: space-between;
    grid-gap: 12px;
}

.action_required_fade_modal_v2_wr .action_required_fade_progress_line_con {
    position: relative;
    width: 100%;
}

.action_required_fade_modal_v2_wr .action_required_fade_progress_line_1 {
    position: absolute;
    width: 100%;
    height: 4px;
    left: 0;
    top: 9px;
    background: #ddd;
    z-index: 1;

}

.action_required_fade_modal_v2_wr .action_required_fade_progress_line_2 {
    position: absolute;
    width: 100%;
    height: 4px;
    left: 0;
    top: 9px;
    background: #9747FF;
    z-index: 2;
    width: 50%;
}

.action_required_fade_progress_line_wr .action_required_fade_progress_line_count {
    flex-shrink: 0;
    font-size: var(--body2-font-size);
    font-weight: var(--body2-font-weight);
    color: var(--text-secondary-color);
}

.action_required_fade_modal_v2_wr .action_required_fade_mvb_check_list {
    display: flex;
    align-items: center;
    grid-gap: 20px;
}

.action_required_fade_modal_v2_wr .action_required_fade_mvb_check_list_l+.action_required_fade_mvb_check_list_l {
    padding: 10px 0 0 0;
}

.action_required_fade_modal_v2_wr .action_required_fade_mvb_check_list span {
    display: inline-flex;
    font-size: var(--body2-font-size);
    font-weight: var(--body2-font-weight);
    letter-spacing: var(--body2-font-letter-spacing);
    line-height: var(--body2-font-line-height);
    color: var(--text-primary-color);
}

.action_required_fade_modal_v2_wr .action_required_fade_mvb_check_list .action_required_fade_mvb_check_list_arrow {
    display: flex;
    align-items: center;
    grid-gap: 5px;
    cursor: pointer;
}

.action_required_fade_modal_v2_wr .action_required_fade_mvb_drop_content li {
    cursor: pointer;
}

.action_required_fade_modal_v2_wr .action_required_fade_mvb_drop_content .arfmdc_sub_list_done_li,
.action_required_fade_modal_v2_wr .action_required_fade_mvb_check_list .arfmcla_completed {
    text-decoration: line-through;
    color: rgba(84, 99, 118, 0.66) !important;
    cursor: unset !important;
}

.action_required_fade_modal_v2_wr .arfmvw_loading_state {
    min-height: 450px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 14px;
}

.action_required_fade_modal_v2_wr .action_required_fade_mvb_cl_w .action_required_fade_mvb_check_list+.action_required_fade_mvb_check_list {
    padding: 16px 0 0 0;
}

.action_required_fade_modal_v2_wr .action_required_fade_mvb_check_list_drop {
    position: relative;
}

.action_required_fade_modal_v2_wr .action_required_fade_mvb_check_list_drop .action_required_fade_mvb_drop_content {
    padding: 0 0 0 53px;
}

.action_required_fade_modal_v2_wr .action_required_fade_mvb_check_list_drop .action_required_fade_mvb_drop_content li {
    font-size: var(--helper-text-font-size);
    font-weight: var(--helper-text-font-weight);
    letter-spacing: var(--helper-text-font-letter-spacing);
    line-height: var(--helper-text-font-line-height);
    color: var(--text-primary-color);
    list-style: disc;
    padding: 6px 0 0 0;

}

.action_required_fade_modal_v2_wr .action_required_fade_mvb_check_list_drop .action_required_fade_mvb_drop_content li span {
    display: flex;
    align-items: center;
}

.action_required_fade_modal_v2_wr .action_required_fade_mvb_r {}

/* Action Required Modal End */


/* folder right side design start */


.MarketingModule-container-parent-class .marketing_list_v2_container .mlvc_body_container_top_serch {
    display: grid;
    grid-template-columns: 7fr 5fr;
    align-items: center;
    padding: 0 8px;
    grid-gap: 20px;
    /* border-bottom: 1px solid var(--other-outline-border); */
}

.MarketingModule-container-parent-class .marketing_list_v2_container .mlvc_body_container_top_serch_left {
    position: relative;
    display: flex;
    align-items: center;
    grid-gap: 10px;
    cursor: pointer;
    justify-content: flex-end;
}

.MarketingModule-container-parent-class .marketing_list_v2_container .mlvc_body_container_top_serch_left .mlvc_body_container_top_serch_left_input_wr {
    position: relative;
}

.MarketingModule-container-parent-class .marketing_list_v2_container .mlvc_body_container_tsl_input {
    border: 1px solid var(--other-outline-border);
    border-radius: 4px;
    padding: 0 16px 0 43px;
    height: 37px;
    margin: 0;
    width: 100%;
    max-width: 180px;
}

.MarketingModule-container-parent-class .marketing_list_v2_container .mlvc_body_container_tsl_icon {
    position: absolute;
    left: 13px;
    top: 8px;

}

.MarketingModule-container-parent-class .marketing_list_v2_container .mlvc_body_container_top_ref_resh_btn {
    font-size: 14px;
    background: var(--dark_blue);
    padding: 8px 5px;
    color: #fff;
    border-radius: 4px;
}

.MarketingModule-container-parent-class .marketing_list_v2_container .mlvc_body_container_top_serch_right {
    display: grid;
    grid-template-columns: 2.5fr 2.5fr;
    align-items: center;
    align-content: center;
    grid-gap: 12px;
}

.MarketingModule-container-parent-class .marketing_list_v2_container .mlvc_body_container_tsr_left,
.MarketingModule-container-parent-class .marketing_list_v2_container .mlvc_body_container_tsr_right {
    border: 1px solid var(--other-outline-border);
    padding: 5px 12px;
    height: 38px;
    display: flex;
    align-items: center;
    grid-gap: 8px;
    font-size: var(--input-font-size);
    font-weight: var(--input-font-weight);
    line-height: var(--input-font-line-height);
    letter-spacing: var(--input-font-letter-spacing);
    color: var(--text-secondary-color);
}

.MarketingModule-container-parent-class .marketing_list_v2_container .mlvc_body_container_tsr_left span {
    display: inline-flex;
}

.MarketingModule-container-parent-class .marketing_list_v2_container .mlvc_bcr_c_top_search_bar .mlvc_ecb_sc_pagination {
    padding: 0 7px;
    margin-bottom: 8px;
}

/* top bar end */
.MarketingModule-container-parent-class .marketing_list_v2_container .mlvc_ecb_top_bar {
    display: grid;
    grid-template-columns: 10fr 2fr;
    border-bottom: 1px solid var(--other-outline-border);
    padding: 0 0 16px 0;
    margin: 0 0 16px 0;
    transition: all linear 0.3s;
    align-items: center;

}

.MarketingModule-container-parent-class .marketing_list_v2_container .mlvc_ecb_top_bar .mlvc_ecb__tb_left {
    overflow: hidden;

}

.MarketingModule-container-parent-class .marketing_list_v2_container .mlvc_ecb__tb_left_row {
    display: flex;
    align-items: center;
    grid-gap: 16px;

}

.MarketingModule-container-parent-class .marketing_list_v2_container .mlvc_ecb__tb_left_title_wrp {
    white-space: nowrap;
    word-break: break-word;
    text-overflow: ellipsis;
    overflow: hidden;
}

.MarketingModule-container-parent-class .marketing_list_v2_container .mlvc_ecb__tb_left_title {
    font-size: var(--h6-font-size);
    font-weight: var(--h6-font-weight);
    line-height: var(--h6-font-line-height);
    letter-spacing: var(--h6-font-letter-spacing);
    color: var(--text-primary-color);
    /* white-space: nowrap;
    word-break: break-word;
    text-overflow: ellipsis;
    overflow: hidden;
    display: block; */
}

.MarketingModule-container-parent-class .marketing_list_v2_container .mlvc_ecb__tb_left_feature {
    font-size: var(--body2-font-size);
    font-weight: var(--body2-regular-font-weight);
    line-height: var(--body2-font-line-height);
    letter-spacing: var(--body2-font-letter-spacing);
    color: var(--text-secondary-color);
    position: relative;
}

.MarketingModule-container-parent-class .marketing_list_v2_container .mlvc_ecb__tb_comon {
    position: relative;
}

.MarketingModule-container-parent-class .marketing_list_v2_container .mlvc_ecb__tb_comon::after {
    position: absolute;
    content: "";
    right: -8px;
    top: 3px;
    height: 15px;
    width: 1px;
    background: var(--other-outline-border);
}

.MarketingModule-container-parent-class .marketing_list_v2_container .mlvc_ecb__tb_comon:last-child::after {
    display: none;
}

.MarketingModule-container-parent-class .marketing_list_v2_container .mlvc_ecb__tb_left_date {
    font-size: var(--body2-font-size);
    font-weight: var(--body2-regular-font-weight);
    line-height: var(--body2-font-line-height);
    letter-spacing: var(--body2-font-letter-spacing);
    color: var(--text-secondary-color);
    display: flex;
    grid-gap: 4px;
}

.MarketingModule-container-parent-class .marketing_list_v2_container .mlvc_ecb__tb_left_folder_title {
    font-size: var(--body2-font-size);
    font-weight: var(--body2-regular-font-weight);
    line-height: var(--body2-font-line-height);
    letter-spacing: var(--body2-font-letter-spacing);
    color: var(--text-secondary-color);
    display: flex;
    grid-gap: 4px;
    align-items: center;
}

.MarketingModule-container-parent-class .marketing_list_v2_container .mlvc_ecb__tb_left_date span,
.MarketingModule-container-parent-class .marketing_list_v2_container .mlvc_ecb__tb_left_folder_title span {
    display: inline-flex;
}

.MarketingModule-container-parent-class .marketing_list_v2_container .mlvc_ecb_stats_container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    grid-gap: 20px;
    transition: all linear 0.3s;

}

.MarketingModule-container-parent-class .marketing_list_v2_container .mlvc_ecb_sc_left {
    display: flex;
    align-items: center;
    grid-gap: 20px;
}

.MarketingModule-container-parent-class .marketing_list_v2_container .mlvc_ecb_sc_l_each_stats {
    display: flex;
    grid-gap: 8px;
    align-items: center;
}

.MarketingModule-container-parent-class .marketing_list_v2_container .mlvc_ecb_sc_l_each_stats_cusor_pointer {
    cursor: pointer;
}

.MarketingModule-container-parent-class .marketing_list_v2_container .mlvc_ecb_sc_l_each_stats_icon {
    display: inline-flex;
}

.MarketingModule-container-parent-class .marketing_list_v2_container .mlvc_ecb_sc_l_each_stats_content span {
    display: block;
}

.MarketingModule-container-parent-class .marketing_list_v2_container .mlvc_ecb_sc_l_each_stats_content_value {
    font-size: var(--body1-font-size);
    font-weight: var(--body1-regular-font-weight);
    line-height: var(--body1-font-line-height);
    letter-spacing: var(--body1-font-letter-spacing);
    color: var(--text-primary-color);
}

.MarketingModule-container-parent-class .marketing_list_v2_container .mlvc_ecb_sc_l_each_stats_content_tite {
    font-size: var(--chip2-font-size);
    font-weight: var(--chip2-regular-font-weight);
    line-height: var(--chip2-font-line-height);
    letter-spacing: var(--chip2-font-letter-spacing);
    color: var(--text-secondary-color);
}

.MarketingModule-container-parent-class .mlvc_ecb_sc_right .react-switch-bg .custom-switch-on-icon,
.MarketingModule-container-parent-class .mlvc_ecb_sc_right .react-switch-bg .custom-switch-on-icon {
    font-size: 13px !important;
}

.MarketingModule-container-parent-class .marketing_list_v2_container .mlvc_ecb_sc_right {
    display: flex;
    align-items: center;
    grid-gap: 5px;
    justify-content: flex-end;
}

.MarketingModule-container-parent-class .mlvc_ecb_sc_right .mlvc_ecb_sc_right_drom_menu {
    cursor: pointer;
}

.MarketingModule-container-parent-class .mlvc_ecb_sc_right .mlvc_ecb_sc_right_drom_menu,
.MarketingModule-container-parent-class .mlvc_ecb_sc_right .mlvc_ecb_sc_right_drom_menu>div,
.MarketingModule-container-parent-class .mlvc_ecb_sc_right .mlvc_ecb_sc_right_drom_menu>div>span {
    display: inline-flex;
}

/* folder right side design end */
/* folder body end */

/* you can remove this css, add for testing purpose */
.mlvc_ecb_focus {
    background-color: rgba(98, 88, 255, 0.06);
}


/* custom switch for local compotent */
/* .MarketingModule-container-parent-class .mlvc_ecb_sc_right_switch .react-switch-bg{
    background: var(--white) !important;
    border:1px solid var(--gray-300-color) !important;

}
.MarketingModule-container-parent-class .mlvc_ecb_sc_right_switch .react-switch-handle{
    background: var(--gary-400-color) !important;
} */




/* paginataion css */
.MarketingModule-container-parent-class .marketing_list_v2_container .mlvc_ecb_sc_pagination {
    display: flex;
    justify-content: space-between;
    grid-gap: 8px;
    padding: 0 13px;
    align-items: center;
}

.MarketingModule-container-parent-class .marketing_list_v2_container .mlvc_ecb_sc_pagination_left {
    display: flex;
    grid-gap: 8px;
    font-size: var(--body2-font-size);
    font-weight: var(--body2-r-font-weight);
    line-height: var(--body2-font-line-height);
    letter-spacing: var(--body2-font-letter-spacing);
    color: var(--text-secondary-color);
    align-items: center;
}

.MarketingModule-container-parent-class .marketing_list_v2_container .mlvc_ecb_sc_pagination_left .mlvc_ecb_sc_top_expand_btn {
    border: 1px solid #ddd;
    border-radius: 4px;
    display: inline-flex;
    padding: 5.5px 4px;
    cursor: pointer;
}

.MarketingModule-container-parent-class .mlvc_ecb_sc_pagination_right .mlvc_ecb_sc_pr_wr .mlvc_ecb_sc_pr_wr_item {
    width: 40px;
    height: 40px;
    display: inline-flex;
    border: 1px solid var(--other-outline-border);
    align-items: center;
    justify-content: center;
    border-radius: 4px;
    cursor: pointer;
}

.MarketingModule-container-parent-class .mlvc_ecb_sc_pagination_right {
    display: flex;
    grid-gap: 8px;
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    justify-content: center;
    align-items: center;
    top: 12px;
}

.MarketingModule-container-parent-class .mlvc_ecb_sc_pagination_right .mlvc_ecb_sc_pr_wr {
    display: flex;
    grid-gap: 8px;
}

.MarketingModule-container-parent-class .mlvc_ecb_sc_pagination_right .mlvc_ecb_sc_pr_input {
    width: 40px;
    height: 40px;
    display: inline-flex;
    border: 1px solid var(--other-outline-border);
    align-items: center;
    justify-content: center;
    border-radius: 4px;
    padding: 8px;
    font-size: var(--body2-font-size);
    font-weight: var(--body2-regular-font-weight);
    line-height: var(--body2-font-line-height);
    letter-spacing: var(--body2-font-letter-spacing);
    color: var(--text-primary-color);
    margin: 0;
}

.MarketingModule-container-parent-class .mlvc_ecb_sc_pagination_right .mlvc_ecb_sc_pr_input:focus {
    box-shadow: 0 0 0 rgba(0, 0, 0, 0.0);
    border-bottom-color: var(--other-outline-border);
}

.MarketingModule-container-parent-class .mlvc_ecb_sc_pagination_right .mlvc_ecb_sc_pr_divider {
    color: var(--other-outline-border);
    margin: 0 10px;
    position: relative;
}

.MarketingModule-container-parent-class .mlvc_ecb_sc_pagination_right .mlvc_ecb_sc_pr_divider:after {
    position: absolute;
    content: "";
    top: 5px;
    left: 0;
    width: 1px;
    background: #ddd;
    height: 30px;
    transform: rotate(25deg);
}

.MarketingModule-container-parent-class .mlvc_ecb_sc_pagination_right .mlvc_ecb_sc_pr_icon svg path {
    fill: var(--text-secondary-color)
}

.MarketingModule-container-parent-class .mlvc_ecb_sc_pagination_right .mlvc_ecb_sc_pr_input_value_1 {
    background: var(--primary-outline-background);
    border-color: var(--primary-outline-border) !important;
    color: var(--primary-color);
    margin: 0;
}

.MarketingModule-container-parent-class .mlvc_ecb_sc_pagination_left .mlvc_top_folder_collapese_icon {
    display: inline-flex;
    cursor: pointer;
    transition: all linear 0.5s;
}

.MarketingModule-container-parent-class .mlvc_ecb_sc_pagination_left .mlvc_top_folder_collapese_icon.mlvc_tfci_rotate {
    transform: rotate(-180deg);
}





/* skelton design  start*/
.mlvc_campaign_folder_single_skelton_wr {
    margin: 6px 0 0 0;
}

.mlvc_campaign_folder_single_skelton_wr .mlvc_campaign_folder_single_skelton {
    display: grid;
    grid-template-columns: 2fr 10fr;
    margin-bottom: 2px;

}

.mlvc_campaign_folder_single_skelton_wr .mlvc_campaign_folder_ssk_left .gl_skelton_wr {
    border-radius: 4px 0 0 4px;
}

.mlvc_campaign_folder_single_skelton_wr .mlvc_campaign_folder_ssk_right .gl_skelton_wr {
    border-radius: 0 4px 4px 0;
}

/* skelton design end */




/* for mobile top bar */
.MarketingModule-container-parent-class .marketing_list_v2_container .mlvc_bc_top_bar {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background: #fff;
    padding: 10px 16px;
    border-radius: 8px;
}

.MarketingModule-container-parent-class .marketing_list_v2_container .mlvc_bc_top_bar_left {
    display: flex;
    align-items: center;
    grid-gap: 16px;
}

.MarketingModule-container-parent-class .marketing_list_v2_container .mlvc_bc_top_bar .mlvc_bc_top_bar_left_inner {
    display: inline-flex;
    align-items: center;
    grid-gap: 6px;
}

.MarketingModule-container-parent-class .marketing_list_v2_container .mlvc_bc_top_bar .mlvc_bc_top_bar_left_inner span {
    display: inline-flex;
    font-size: var(--subtitle1-font-size);
    font-weight: var(--subtitle1-font-weight);
    line-height: var(--subtitle1-font-line-height);
    letter-spacing: var(--subtitle1-font-letter-spacing);
    color: var(--text-secondary-color)
}

.MarketingModule-container-parent-class .marketing_list_v2_container .mlvc_bc_top_bar .mlvc_thc_right_side {
    display: flex;
    grid-gap: 8px;
}

.MarketingModule-container-parent-class .marketing_list_v2_container .mlvc_bc_top_bar .meis_custom_button_container {
    padding: 3px 6px !important;
    font-size: 14px !important;
}


.MarketingModule-container-parent-class .mlvc_ecb__tb_left_feature,
.MarketingModule-container-parent-class .mlvc_ecb__tb_left_date {
    min-width: fit-content;
}

.MarketingModule-container-parent-class .mlvc_ecb__tb_lw_folders_wrapper {
    overflow-x: auto;
    display: flex;
    align-items: center;
    gap: 5px;
}

.MarketingModule-container-parent-class .mlvc_ecb__tb_lw_folders_wrapper {
    padding-bottom: 4px;
}

.MarketingModule-container-parent-class .mlvc_ecb__tb_lw_folders_wrapper::-webkit-scrollbar,
.add_new_folder_fade_modal_v2_wr .add_new_folder_fade_modal_v2_body::-webkit-scrollbar {
    width: 6px;
    height: 6px;
}

.MarketingModule-container-parent-class .mlvc_ecb__tb_lw_folders_wrapper::-webkit-scrollbar-track,
.add_new_folder_fade_modal_v2_wr .add_new_folder_fade_modal_v2_body::-webkit-scrollbar-track {
    background: rgba(60, 126, 243, 0.1);
    border-radius: 4px;
}

.MarketingModule-container-parent-class .mlvc_ecb__tb_lw_folders_wrapper::-webkit-scrollbar,
.add_new_folder_fade_modal_v2_wr .add_new_folder_fade_modal_v2_body::-webkit-scrollbar {
    scrollbar-width: thin;
}

.MarketingModule-container-parent-class .mlvc_ecb__tb_lw_folders_wrapper::-webkit-scrollbar-thumb,
.add_new_folder_fade_modal_v2_wr .add_new_folder_fade_modal_v2_body::-webkit-scrollbar-thumb {
    border-radius: 10px;
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    background-color: #949db2;
    box-sizing: border-box;
}

.MarketingModule-container-parent-class .mlvc_ecb__tb_lw_folders_wrapper::-webkit-scrollbar-track,
.add_new_folder_fade_modal_v2_wr .add_new_folder_fade_modal_v2_body::-webkit-scrollbar-track {
    background: #f4f5f7;
    box-shadow: inset 1px 0px 0px rgba(148, 157, 178, 0.12);
    -webkit-box-shadow: inset 1px 0px 0px rgba(148, 157, 178, 0.12);
}



@media screen and (max-width: 1366px) {
    .MarketingModule-container-parent-class .marketing_list_v2_container .mlvc_ecb__tb_left_title {
        font-size: 18px;
    }

    .MarketingModule-container-parent-class .mlvc_ecb_sc_right .react-switch-bg .custom-switch-on-icon,
    .MarketingModule-container-parent-class .mlvc_ecb_sc_right .react-switch-bg .custom-switch-on-icon {
        font-size: 11px !important;
    }

    .MarketingModule-container-parent-class .marketing_list_v2_container .mlvc_each_campaign_body {
        padding: 20px 16px;
    }

    .MarketingModule-container-parent-class .marketing_list_v2_container .mlvc_ecb_sc_left {
        grid-gap: 12px;
    }

    .MarketingModule-container-parent-class .marketing_list_v2_container .mlvc_ecb_sc_right_switch .react-switch-bg {
        width: 80px !important;
    }

    .MarketingModule-container-parent-class .marketing_list_v2_container .mlvc_ecb_sc_right_switch .pype-custom-switch.custom-switch-active-mode .react-switch-handle {
        transform: translateX(60px) !important;
    }

    .MarketingModule-container-parent-class .marketing_list_v2_container .mlvc_ecb_top_bar .mlvc_ecb__tb_right svg {
        width: 16px;
    }

    .MarketingModule-container-parent-class .marketing_list_v2_container .mlvc_ecb__tb_left_row svg {
        width: 19px;
    }

    .MarketingModule-container-parent-class .mlvc_campaign_folder_inner_single .mlvc_campaign_folder_inner_single_row span svg {
        width: 22px;
    }

    /* empty */
    .MarketingModule-container-parent-class .mlvc_body_container .mlvc_bcr_empty_img svg {
        width: 73%;

    }

    .MarketingModule-container-parent-class .mlvc_body_container .mlvc_bcr_empty_rc_title {
        font-size: 20px;
    }
}

@media screen and (max-width: 1240px) {
    .MarketingModule-container-parent-class .mlvc_body_container {
        flex-direction: column;
    }

    .MarketingModule-container-parent-class .mlvc_body_container .mlvc_campaign_folder_container {
        max-width: 100%;
        height: unset;
    }

    .MarketingModule-container-parent-class .mlvc_body_container .mlvc_campaign_folder_inner {
        /* height: auto; */
    }

    .MarketingModule-container-parent-class .marketing_list_v2_container .mlvc_ecb_stats_container {
        grid-gap: 16px;
    }
}












.add_campaign_fm_ic_left_inner .cfamw_b_icpw_iw_icons_item:not(.active_selectd_icon) svg path {
    fill: #546376;
}

.add_campaign_fm_ic_right_inner .add_campaign_fm_single_color .acfsc_white svg path {
    fill: #000000;
}


.mlvc_ecb_sc_pagination_right .mlvc_ecb_sc_pr_wr_i_disabled {
    opacity: 0.5;
    cursor: not-allowed !important;
}


.add_new_folder_fade_modal_v2_wr .MuiFormControlLabel-root.Mui-disabled {
    opacity: 0.5;
}

.MarketingModule-container-parent-class .mlvc_campaign_folder_inner_single.mlvc_cfc_collapse_each_folder {
    display: flex;
    align-items: center;
    justify-content: center;
}

.MarketingModule-container-parent-class .mlvc_campaign_folder_inner_single.mlvc_cfc_collapse_each_folder .mlvc_cfc_collapse_svg_icon svg {
    width: 24px;
    height: 24px;
}

.MarketingModule-container-parent-class .mlvc_cfc_collapse .mlvc_campaign_crete_bottom_btn {
    background: var(--dark_blue);
    padding: 7px;
    margin: 0 8px;
    border-radius: 4px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.MarketingModule-container-parent-class .mlvc_cfc_collapse .mlvc_campaign_folder_create {
    padding-top: 0 !important;
}

.MarketingModule-container-parent-class .mlvc_cfc_collapse .mlvc_campaign_folder_create span svg path {
    fill: #fff;
}

.MarketingModule-container-parent-class .mlvc_cfc_collapse .mlvc_campaign_folder_inner {
    height: calc(100vh - 219px);
}



/* for test */
.meis_hyper_tag_show_link {
    display: block;
    max-width: 75%;
    left: 0;
    bottom: 0;
    position: absolute;
    z-index: 9999999;
    background-color: royalblue;
    font-size: 12px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.meis_hyper_tag_show_link.hide_meishtsl {
    display: none !important;
}



/* drag */
.dc_each {
    height: 80px;
    width: 200px;
    background-color: #006DF5;
}

.copy_drag {
    height: 40px;
    width: 100px;
    background-color: red;
    display: flex;
    align-items: center;
    justify-content: center;
    display: none;
    padding: 10px;
}

.copy_drag.need_to_view {
    display: block !important;
}

.mlvc_campaign_folder_container .mlvc_campaign_folder_create .create-svg-icon {
    color: var(--accent-color);
}

.mlvc_campaign_folder_container .mlvc_campaign_folder_create .create-svg-icon svg path {
    fill: var(--accent-color) !important;
}

.MarketingModule-container-parent-class .marketing_list_v2_container .mlvc_body_container_top_serch_left .topBar-create-button {
    background-color: var(--accent-color);
    color: var(--accent-text-color);
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 8px;
    width: 100px;
    height: 38px;
}

.MarketingModule-container-parent-class .marketing_list_v2_container .mlvc_body_container_top_serch_left .mlvc_campaign_new_camp_create {
    display: flex;
    align-items: center;
    justify-content: center;
    grid-gap: 10px;
    font-size: var(--button-medium-font-size);
    font-weight: var(--button-medium-font-weight);
    line-height: var(--button-medium-font-line-height);
    letter-spacing: var(--button-medium-font-letter-spacing);
    background: var(--secondary-color);
    color: var(--white);
    cursor: pointer;
    padding: 2px 10px;
    justify-content: center;
    border-radius: 4px;
    margin-right: 12px;
}

.MarketingModule-container-parent-class .marketing_list_v2_container .mlvc_body_container_top_serch_left .mlvc_campaign_new_camp_create svg path {
    fill: var(--white) !important;
}


.MarketingModule-container-parent-class .mlvc_body_container_middle_bottom_fixed .mlvc_body_container_middle.middle-box {
    height: calc(100vh - 285px) !important;
}

.MarketingModule-container-parent-class .mlvc_body_container .mlvc_campaign_folder_container.container-wrapper {
    max-width: 225px !important;
}

.mlvc_campaign_folder_container .mlvc_campaign_folder_inner.inner-wrapper{
    height: calc(100vh - 210px);
}

.MarketingModule-container-parent-class .marketing_list_v2_container .campaign_status_filter_new.dropDown-box {
    padding: 5px 0px 5px 10px;
    justify-content: space-between;
}

.MarketingModule-container-parent-class .marketing_list_v2_container .mlvc_body_container_top_serch.main-container {
    grid-template-columns: 4fr 8fr !important;
}

.MarketingModule-container-parent-class .marketing_list_v2_container .mlvc_body_container_tsl_input.input-width {
    max-width: 250px;
}

.MarketingModule-container-parent-class .mlvc_body_container .mlvc_bcr_empty_row.empty-page {
    height: calc(100vh - 240px) !important;
}
