.custom-scheduling-tab .m-portlet__head .react-tabs-header ul {
    justify-content: left !important;
}
.custom-scheduling-tab .time_picker_preview {
    height: 40px;
}
.custom-scheduling-tab .react_times_button {
    box-shadow: unset !important;
    border: 1px solid #ddd;
}
.timepicker-wrapper{
    width:160px;
}
.timepicker-wrapper .time_picker_modal_container{
    top: auto;
    bottom: 85px;
    width: auto;
    -webkit-box-shadow: 0 0 10px rgba(0,0,0,0.2);
    box-shadow: 0 0 10px rgba(0,0,0,0.2);
    z-index: 999 !important;
}
.popup-tab .m-portlet__head{
    padding:0 !important;
}
.popup-tab .m-portlet__head ul.react-tabs__tab-list li.react-tabs__tab{
    flex:1;
    border: 0;
    border-right: 1px solid #ddd;
    bottom:0;
    height: 48px;
    line-height: 46px;
    padding: 0 15px;
    text-align: center;
}
.popup-tab .m-portlet__head ul.react-tabs__tab-list li.react-tabs__tab:last-child{
    border-right:0;
}
.popup-tab .m-portlet__head ul.react-tabs__tab-list li.react-tabs__tab--selected{
    border-radius: 0;
    bottom:0;

}
.preview_container {
 position: absolute;
 left: 50%;
 height: 50px;
 line-height: 50px;
 padding-left: 30px;
 transform: translateX(-50%);
 -o-transform: translateX(-50%);
 -ms-transform: translateX(-50%);
 -webkit-transform: translateX(-50%);
 -moz-transform: translateX(-50%);
}
.custom-scheduling-tab .preview_container {
    display: flex;
    align-items: center;
    height: 38px;
}
.custom-scheduling-tab .preview_container svg {
    width: 20px;
    height: 20px;
    position: absolute;
    top: 8px;
}
.custom-scheduling-tab .react-datepicker-wrapper {
    margin: 0px 1px;
}
@media (max-width: 1199px) {
    .custom-scheduling-tab .m-portlet__head ul.react-tabs__tab-list li.react-tabs__tab {
        font-size: 10px;
    }
}

.react-tabs-header ul {
    display: flex;
    width: 100%;
}