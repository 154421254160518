/* ===========Modal css============== */
.add_leadflow_modal{
    left: unset;
    top: 81px !important;
    background: #F6F7FB;
    border-radius: 10px 0px 0px 10px;
    max-width: 820px;
    width: 100%;
    position: fixed;
    right: 0;
    padding: 0;
    max-height: 70%;
    margin: auto;
    overflow-y: auto;
    overflow-x: hidden;
    will-change: top, opacity;
}
.add_leadflow_modal:focus {
    outline: none;
}
.add_leadflow_modal .contact_source_container{
    min-height: 200px;
}

.top_content {
    border-bottom: 1px solid #E7EFF0;
}
.sub_title h2 {
    font-size: 24px;
    line-height: 30px;
    font-weight: 500;
    text-transform: capitalize;
    letter-spacing: 0.03em;
    color: var(--dark_blue);
    margin: 0;
}
.cross_button {
    background: var(--red);
    width: 26px;
    height: 26px;
    border-radius: 50%;
    cursor: pointer;
}
.modal__buttons{
    padding: 30px;
    border-top: 1px solid #E7EFF0;
}
.add__group__btn, .modal__close__btn{
    font-size: 14px;
    line-height: 30px;
    height: 50px;
    font-weight: 500;
    letter-spacing: 0.01em;
    color: var(--white);
    display: flex;
    align-items: center;
    padding: 10px 20px;
    border-radius: 10px;
    background-color: #2F80ED;
    border: none;
    cursor: pointer;
}
.add__group__btn:focus, .modal__close__btn:focus{
    background-color: #2F80ED !important;
}
.modal__close__btn {
    background-color: var(--red);
    margin-left: 20px;
}
.input_field_group{
    padding: 20px 10px 0px 15px;
}
.lead_src_select input,
.title_input_field input{
    background: #FFFFFF  !important;
    border: 1px solid rgba(19, 49, 89, 0.35) !important;
    box-sizing: border-box !important;
    border-radius: 5px !important;
    padding: 24px 20px !important;
    font-weight: normal;
    font-size: 16px !important;
    line-height: 22px;
    letter-spacing: 0.01em;
    color: rgba(19, 49, 89, 0.35);
}
.title_input_field input:focus{
    border-bottom: 1px solid rgba(19, 49, 89, 0.35) !important;
    box-shadow: unset !important;
}
.lead_src_select label,
.title_input_field label{
    font-weight: normal;
    font-size: 16px;
    line-height: 30px;
    color: #133159;
}
.lead_src_select .MuiInput-underline:before{
    display: none !important;
}
.lead_src_select .MuiInput-underline:after{
    display: none !important;
}
.lead_src_select .MuiInputBase-root{
    display: block !important;
}
.lead_src_select .MuiSelect-select{
    background: #FFFFFF !important;
    border: 1px solid rgba(19, 49, 89, 0.35) !important;
    box-sizing: border-box !important;
    border-radius: 5px !important;
    padding: 13px 20px !important;
    font-weight: normal;
    font-size: 16px !important;
    line-height: 22px;
    letter-spacing: 0.01em;
    color: rgba(19, 49, 89, 0.35);
}


.lead_src_select .check_icon,
.title_input_field span{
    color: red !important;
}
.lead_src_select svg{
    z-index: 1 !important;
    fill: rgba(84, 99, 118, 1)!important;
}