.global--quick-reply-card-content {
    height: 280px;
    background: #f6f7fb;
    border: 1px solid #ddd;
    border-radius: 6px;
    margin: 5px;
    width: auto;
    display: inline-block;
    position: relative;
}
.global--quick-reply-card-content .quick-reply-header{
    width: 100%;
    display: flex;
    padding: 10px;
    height: 55px;
    border-bottom: 1px solid #ddd;
    justify-content: space-between;
    align-items: center;
}
.global--quick-reply-card-content .quick-reply-title{
    padding-right: 20px;
    color: var(--dark_blue);
    font-size: 15px;
}
.global--quick-reply-card-content .quick-reply-type-content{
    padding: 3px 10px;
    border: 1px solid #ddd;
    border-radius: 6px;
    align-items: center;
    justify-content: center;
    display: flex;
}
.global--quick-reply-card-content .quick-reply-type-text{
    color: var(--dark_blue);
    font-size: 13px;
    margin-left: 5px;
}
.global--quick-reply-card-content .quick-reply-type-icon{
    color: #3C7EF3;
    align-items: center;
    display: flex;
}
.global--quick-reply-card-content .quick-reply-footer{
    border-top: 1px solid #ddd;
    height: 51px;
    justify-content: center;
    align-items: center;
    display: flex;
    cursor: pointer;
}
.global--quick-reply-card-content .quick-reply-body{
    height: calc(100% - 111px);
    padding: 5px;
}
.global--quick-reply-card-content .quick-reply-footer .quick-reply-select-button{
    color: var(--dark_blue);
    font-size: 15px;
}
.global--quick-reply-card-content .quick-reply-body .quick-reply-body-content{
    background-color: #edf6fa;
    border-radius: 6px;
    padding: 10px;
    /* text-align: justify; */
}
.global--quick-reply-card-content .quick-reply-email-subject {
    margin-bottom: 10px;
}
.global--quick-reply-card-content .quick-reply-email-subject .__subject_title{
    text-decoration: underline;
    margin-right: 5px;
    color: var(--dark_blue);
}
.global--quick-reply-card-content .__subject_text {
    color: var(--dark-blue-65);
}
.global--quick-reply-card-content .quick-reply-email-message .__message_title{
    text-decoration: underline;
    margin-right: 5px;
    color: var(--dark_blue);
}
.global--quick-reply-card-content .quick-reply-sms-message{
    text-align: justify;
    color: var(--dark_blue);
    font-size: 15px;
    word-break: break-word;;
}
.global--quick-reply-card-content .quick-reply-voice-message{
    height: 100%;
    justify-content: center;
    align-items: center;
    display: flex;
}
.global--quick-reply-card-content .quick-reply-video-email{
    height: 100%;
}
.global--quick-reply-card-content .quick-reply-video-email .quick-reply-video-email-gif{
    height: 100%;
    width: 100%;
}
.global--quick-reply-card-content .quick-reply-mms{
    width: 100%;
}
.global--quick-reply-card-content .quick-reply-mms .__mms-message{
    color: var(--dark_blue);
    word-break: break-word;
}
.global--quick-reply-card-content .quick-reply-mms .__mms-img{
    width: 100%;
    margin-top: 10px;
}
.global--quick-reply-card-content .quick-reply-pdf-file{
    width: 100%;
}
.global--quick-reply-card-content .quick-reply-pdf-file .__pdf-message{
    word-break: break-word;
    color: var(--dark_blue);
}
.global--quick-reply-card-content .quick-reply-pdf-file .__pdf-file{
    width: 100%;
    margin-top: 10px;
}
.global--quick-reply-card-content .quick-reply-icon{

}
.global--quick-reply-card-content .quick-reply-email-message .__message_text p{
    word-wrap: break-word;
}
.main-content-from-quick-reply{
    height: 370px !important;
    /* width: unset !important; */
    flex: 0 1 32%;
}

/* for quick reply module */
.global--quick-reply-card-content .quick-reply-card-alt-content{
    display: flex;
    align-items: center;
    justify-content: center;
}
.global--quick-reply-card-content .quick-reply-card-alt-campaign{
    border-radius: 6px;
    cursor: pointer;
    color: #fff;
    align-items: center;
    display: flex;
    margin-right: 10px;
    height: 38px;
    padding: 0px 12px;
}
.global--quick-reply-card-content .quick-reply-card-alt-campaign svg{
    height: 12px;
}

.global--quick-reply-card-content .quick-reply-card-alt-campaign span{
    font-size: 12px;
    font-weight: 500;
}
.global--quick-reply-card-content .quick-reply-card-alt-campaign-edit-button{
    height: 38px !important;
    padding: 0px 12px !important;
    margin-right: 10px;
}
.global--quick-reply-card-content .quick-reply-card-alt-campaign-edit-button svg{
    font-size: 19px;
    margin-right: 5px;
}
.global--quick-reply-card-content .quick-reply-card-alt-campaign-edit-button span{
    font-size: 12px;
    font-weight: 500;
    text-transform: capitalize;
}
.global--quick-reply-card-content .quick-reply-card-alt-campaign-delete-button{
    display: flex;
    background-color: var(--red);
    color: #fff;
    height: 38px;
    border-radius: 6px;
    justify-content: center;
    align-items: center;
    padding: 0px 12px !important;
}
.global--quick-reply-card-content .quick-reply-card-alt-campaign-delete-button svg{
    height: 19px;
    margin-right: 5px;
    color: #ffffff !important;
}
.global--quick-reply-card-content .quick-reply-card-alt-campaign-delete-button span{
    font-size: 12px;
    font-weight: 500;
}
.global--quick-reply-card-content.main-content-from-quick-reply .quick-reply-body img{
    width:100%
}
.global--quick-reply-card-content .quick-reply-card-clone-campaign{
    border-radius: 6px;
    cursor: pointer;
    color: #fff;
    align-items: center;
    display: flex;
    margin-right: 10px;
    height: 38px;
    padding: 0px 12px;
}
.global--quick-reply-card-content .quick-reply-card-clone-campaign svg{
    height: 15px;
    margin-right: 4px;
}
.global--quick-reply-card-content .quick-reply-card-clone-campaign span{
    font-size: 12px;
    font-weight: 500;
}
@media(max-width: 1024px) and (min-width: 768px) {
    .main-content-from-quick-reply {
        flex: 1 0 30%;
    }
}