.MarketingModule-container-parent-class .campaign_continue_after_failed_container{
    display: flex;
    align-items: center;
    grid-gap: 8px;
    background: var(--gray_light);
    padding: 12px;
    border-radius: 5px;
    justify-content: space-between;
}
.MarketingModule-container-parent-class .campaign_continue_afc_row{
    display: flex;
    align-items: center;
}
.MarketingModule-container-parent-class .campaign_continue_afc_row span{
    display: inline-flex;
}