.lead-group-add-modal {
	/*********************** Group Lists Table CSS ************************/
	/*********************** Group Lists Modal CSS ************************/
	/* .modal__search__input:focus + .modal__search__preview {
		 display: block !important;
	}
	 */
}
 .lead-group-add-modal .top_content {
	 border-bottom: 1px solid #e7eff0;
}
 .lead-group-add-modal .sub_title h2 {
	 font-size: 24px;
	 line-height: 30px;
	 font-weight: 700;
	 text-transform: capitalize;
	 letter-spacing: 0.03em;
	 color: var(--dark_blue);
	 margin: 0;
}
 .lead-group-add-modal .cross_button {
	 background: var(--red);
	 width: 26px;
	 height: 26px;
	 border-radius: 50%;
	 cursor: pointer;
}
 .lead-group-add-modal .active__minimal_sidebar .awesome__scroll_bar::-webkit-scrollbar {
	 width: 12px !important;
}
 .lead-group-add-modal .main__top {
	 border-bottom: 1px solid rgba(60, 126, 243, 0.1);
	 margin-bottom: 19px;
	 padding: 0 20px;
}
 .lead-group-add-modal .main__wrapper {
	 background-color: var(--white);
	 margin-top: 20px;
	 margin-bottom: 20px;
	 border-radius: 10px;
	 position: relative;
}
 .lead-group-add-modal .main__top__text h1 {
	 font-weight: 500;
	 font-size: 24px;
	 line-height: 36px;
	 color: var(--dark_blue);
	 letter-spacing: 0.01em;
}
 .lead-group-add-modal .main__top__text p {
	 font-weight: 500;
	 font-size: 16px;
	 line-height: 28px;
	 margin-bottom: 0;
	 color: #133159 59;
}
 .lead-group-add-modal .main__top__text p span {
	 color: var(--blue-1);
}
 .lead-group-add-modal .add__group__btn, .lead-group-add-modal .modal__close__btn {
	 font-family: var(--poppins);
	 font-style: normal;
	 font-weight: normal;
	 font-size: 16px;
	 line-height: 30px;
	 height: 30px;
	 font-weight: 500;
	 letter-spacing: 0.01em;
	 color: var(--white);
	 display: flex;
	 align-items: center;
	 padding: 25px 14px;
	 border-radius: 5px;
	 background-color: var(--blue-1);
	 border: none;
	 cursor: pointer;
}
 .lead-group-add-modal .main__bottom {
	 color: var(--dark_blue);
	 margin-right: -18px;
	 height: 690px;
	 padding: 0 20px;
}
 .lead-group-add-modal .main__bottom.awesome__scroll_bar {
	 padding-bottom: unset;
}
 .lead-group-add-modal .main__bottom.awesome__scroll_bar::-webkit-scrollbar-thumb {
	 background-color: var(--dark_blue);
}
 .lead-group-add-modal .main__bottom.awesome__scroll_bar::-webkit-scrollbar-track {
	 background-color: rgba(60, 126, 243, 0.1);
}
 .lead-group-add-modal .custom__thead {
	 background-color: var(--gray_dark);
}
 .lead-group-add-modal .custom__thead tr {
	 border-bottom: none;
}
 .lead-group-add-modal .custom__thead tr th {
	 border-left: 2px solid var(--white);
	 padding: 10px 25px;
	 font-family: var(--poppins);
	 font-weight: 400;
	 font-size: 20px;
	 line-height: 30px;
	 color: var(--dark_blue);
}
 .lead-group-add-modal .custom__tbody {
	 border: none;
}
 .lead-group-add-modal .custom__tbody tr td {
	 padding-left: 25px;
	 font-family: var(--poppins);
	 font-weight: 400;
	 font-size: 16px;
	 line-height: 30px;
	 color: var(--dark_blue);
	 padding: 6px 20px !important;
}
 .lead-group-add-modal .custom__tbody tr {
	 border-bottom: 3px solid rgba(60, 126, 243, 0.05) !important;
}
 .lead-group-add-modal .custom__tbody tr:first-child {
	 border-top: 0px;
}
 .lead-group-add-modal .distribution__icon {
	 background-color: var(--blue-1);
	 color: var(--white);
	 border-radius: 100%;
	 width: 36px;
	 height: 36px;
	 display: flex;
	 align-items: center;
	 justify-content: center;
	 cursor: pointer;
	 margin-right: 15px;
}
 .lead-group-add-modal .distribution__btn {
	 padding-left: 0px;
}
 .lead-group-add-modal .distribution__btn img {
	 width: 36px;
	 height: 36px;
}
 .lead-group-add-modal .group__edit__btn, .lead-group-add-modal .group__delete__btn, .lead-group-add-modal .distribution__btn {
	 border: none;
	 background-color: transparent;
	 cursor: pointer;
}
 .lead-group-add-modal .group__edit__btn:focus-within, .lead-group-add-modal .group__delete__btn:focus-within, .lead-group-add-modal .distribution__btn:focus-within {
	 border: none;
	 background-color: transparent;
}
 .lead-group-add-modal #addToGroup {
	 background-color: var(--white);
	 top: 80px !important;
	 left: unset;
	 max-width: 1170px;
	 border-radius: 10px 0 0 10px;
	 max-height: 88%;
}
 .lead-group-add-modal .add__group__btn:focus-within {
	 background-color: var(--blue-1);
}
 .lead-group-add-modal .modal-content {
	 padding: unset !important;
}
 .lead-group-add-modal .modal__content__top h1 {
	 font-weight: 500;
	 font-size: 24px;
	 line-height: 36px;
	 color: var(--dark_blue);
	 border-bottom: 1px solid #efebff;
	 padding-bottom: 17px;
}
 .lead-group-add-modal .modal__input__box label {
	 font-weight: 500;
	 font-size: 16px;
	 line-height: 24px;
	 display: block;
	 color: var(--dark_blue);
	 margin-bottom: 8px;
}
 .lead-group-add-modal .modal__input {
	 background-color: var(--gray_light) !important;
	 border-bottom: none !important;
	 border-radius: 5px !important;
	 box-shadow: none !important;
	 padding: 7px 17px !important;
	 margin-bottom: 20px !important;
	 box-sizing: border-box !important;
	 border: 1px solid #e5e8ef !important;
	 font-size: 16px !important;
	 line-height: 24px;
	 display: flex;
	 align-items: center;
	 letter-spacing: 0.01em;
	 color: #bababa;
	 height: 40px !important;
	 font-family: var(--poppins);
}
 .lead-group-add-modal .modal__input__search {
	 background-color: var(--white) !important;
	 border-bottom: none !important;
	 border-radius: 5px !important;
	 box-shadow: none !important;
	 padding: 11px 17px !important;
	 box-sizing: border-box !important;
	 font-size: 16px;
	 line-height: 22px;
	 letter-spacing: 0.01em;
	 color: #546376;
	 font-family: var(--poppins);
}
 .lead-group-add-modal .modal__input::placeholder {
	/* Chrome, Firefox, Opera, Safari 10.1+ */
	 color: var(--gray);
	 opacity: 1;
	/* Firefox */
	 font-size: 16px;
	 font-weight: 400;
	 line-height: 22px;
}
 .lead-group-add-modal .modal__input:-ms-input-placeholder {
	/* Internet Explorer 10-11 */
	 color: var(--gray);
}
 .lead-group-add-modal .modal__input ::-ms-input-placeholder {
	/* Microsoft Edge */
	 color: var(--gray);
}
 .lead-group-add-modal .modal__input__search::placeholder {
	/* Chrome, Firefox, Opera, Safari 10.1+ */
	 color: var(--gray);
	 opacity: 1;
	/* Firefox */
	 font-size: 16px;
	 font-weight: 400;
	 line-height: 22px;
}
 .lead-group-add-modal .modal__input__search:-ms-input-placeholder {
	/* Internet Explorer 10-11 */
	 color: var(--gray);
}
 .lead-group-add-modal .modal__input__search ::-ms-input-placeholder {
	/* Microsoft Edge */
	 color: var(--gray);
}
 .lead-group-add-modal .modal__search__box {
	 background-color: #f6f7fb;
	 padding: 20px;
	 border-radius: 10px;
	 border: 1px solid #e5e8ef;
	 position: relative;
}
 .lead-group-add-modal .modal__search__box span.serach_icon {
	 position: absolute;
	 right: 33px;
	 top: 31px;
	 cursor: pointer;
}
 .lead-group-add-modal .modal__search__preview {
	 height: 227px;
	 padding-right: 5px;
}
 .lead-group-add-modal .modal__search__preview.awesome__scroll_bar {
	 padding-bottom: unset;
}
 .lead-group-add-modal .modal__search__preview.awesome__scroll_bar::-webkit-scrollbar-track {
	 background-color: rgba(60, 126, 243, 0.1);
	 scrollbar-color: rgba(60, 126, 243, 0.1);
}
 .lead-group-add-modal .modal__search__preview.awesome__scroll_bar::-webkit-scrollbar-thumb {
	 background-color: var(--dark_blue);
}
 .lead-group-add-modal [type="checkbox"].filled-in:checked + span:not(.lever):after {
	 background-color: var(--light_blue) !important;
}
 .lead-group-add-modal .modal__search__preview .checkbox__text_1::after {
	 border: 1px solid var(--light_blue) !important;
}
 .lead-group-add-modal .modal__search__preview label {
	 background-color: var(--white);
	 padding: 10px 10px;
	 border-radius: 5px;
	 font-size: 16px;
	 margin-top: 10px;
}
 .lead-group-add-modal .modal__search__preview label:hover {
	 background-color: rgba(60, 126, 243, 0.1);
}
 .lead-group-add-modal .modal__checkbox {
	 background-color: var(--gray_light);
	 padding: 20px;
	 margin-right: 10px;
	 height: 93px;
	 margin-top: 20px;
	 border-radius: 5px;
}
 .lead-group-add-modal .modal__checkbox label {
	 display: flex;
}
 .lead-group-add-modal .checkbox__text__holder {
	 display: flex;
	 flex-direction: column;
}
 .lead-group-add-modal .checkbox__text__holder::after {
	 border: 1px solid var(--light_blue) !important;
}
 .lead-group-add-modal .checkbox__text__holder .checkbox__text_1 {
	 padding-left: 0px !important;
}
 .lead-group-add-modal .checkbox__text_1 {
	 font-weight: 500;
	 font-size: 16px !important;
	 color: #546376;
	 line-height: 22px !important;
	 letter-spacing: 0.01em;
	 padding-left: 40px !important;
}
 .lead-group-add-modal .checkbox__text_2, .lead-group-add-modal .modal__select__text {
	 font-style: normal;
	 font-size: 16px;
	 font-weight: 400;
	 color: #99a6b9;
	 line-height: 22px;
	 margin-right: 20px;
	 letter-spacing: 0.01em;
}
 .lead-group-add-modal .checkbox__text__holder span {
	 display: block;
}
 .lead-group-add-modal .selector__box {
	 margin-left: 20px;
	 background-color: var(--gray);
	 border-radius: 10px;
}
 .lead-group-add-modal .selector__box .select-wrapper {
	 padding: 5px 10px !important;
	 border-bottom: none !important;
}
 .lead-group-add-modal .select-wrapper .caret {
	 fill: var(--white) !important;
	 font-size: 16px;
}
 .lead-group-add-modal .select-wrapper .dropdown-content {
	 background-color: var(--gray);
}
 .lead-group-add-modal .select-wrapper .dropdown-content li span {
	 color: var(--white);
	 font-size: 16px;
}
 .lead-group-add-modal .selector__box .select-wrapper input {
	 background-color: transparent !important;
	 border-bottom: none !important;
	 color: var(--white);
	 font-size: 16px;
	 height: 2rem;
	 max-width: 140px;
}
 .lead-group-add-modal .modal-footer {
	 height: 70px !important;
	 padding: 0 20px !important;
	 margin-bottom: 20px;
	 background: #fff !important;
}
 .lead-group-add-modal .modal__buttons {
	 padding-top: 20px;
	 border-top: 1px solid #efebff;
}
 .lead-group-add-modal .modal__close__btn {
	 background-color: var(--red);
	 margin-left: 35px;
}
 .lead-group-add-modal .modal__close__btn:focus-within {
	 background-color: var(--red);
}
 .lead-group-add-modal .add_grp_select input {
	 border-bottom: unset !important;
	 background: #546376 !important;
	 color: white;
	 border-radius: 5px !important;
	 height: 30px !important;
	 width: 120px !important;
	 padding: 0px 10px !important;
	 font-weight: 500;
	 font-size: 16px !important;
	 line-height: 24px !important;
	 letter-spacing: 0.01em;
	 font-family: var(--poppins);
}
 .lead-group-add-modal .add_grp_select .caret {
	 display: none;
}
 .lead-group-add-modal .add_grp_select .select_icon {
	 position: absolute;
	 right: 12px;
	 top: 3px;
	 z-index: 1;
}
 .lead-group-add-modal .group__delete__btn span, .lead-group-add-modal .group__edit__btn span {
	 height: 30px;
	 width: 30px;
	 border-radius: 50%;
	 border: 1px solid rgba(19, 49, 89, 0.35);
	 display: block;
	 line-height: 27px;
}
 .lead-group-add-modal .group__delete__btn span {
	 border: 1px solid rgba(255, 88, 88, 0.6);
}
 .lead-group-add-modal .massaging_service {
	 max-width: 1170px;
	 background: var(--gray_light);
	 border-radius: 10px 0px 0px 10px;
	 box-shadow: 0px 6px 15px rgba(0, 0, 0, 0.1);
	 left: unset;
	 top: 80px !important;
	 right: 0 !important;
	 position: fixed;
	 max-height: 80%;
	 overflow-y: auto;
}
 .lead-group-add-modal .massaging_service.modal {
	 max-height: 88% !important;
}
 .lead-group-add-modal .massaging_service_title {
	 border-bottom: 2px solid var(--white);
}


.modal__search__preview label {
	position: relative;
}

.modal__search__preview label input {
	width: 20px;
	height: 20px;
	top: 10px;
}

 @media (min-width: 1201px) and (max-width: 1366px) {
	 .lead-group-add-modal .modal__search__preview {
		 height: 160px;
	}
	 .lead-group-add-modal .checkbox__text_2, .lead-group-add-modal .modal__select__text {
		 font-size: 14px;
	}
	 .lead-group-add-modal .main__bottom {
		 height: 530px;
	}
}
 @media (min-width: 992px) and (max-width: 1201px) {
	 .lead-group-add-modal .main__bottom {
		 height: 530px;
	}
	 .lead-group-add-modal .checkbox__text_2, .lead-group-add-modal .modal__select__text {
		 font-size: 14px;
	}
}
 @media (min-width: 992px) and (max-width: 1024px) {
	 .lead-group-add-modal .modal {
		 width: 64% !important;
	}
}
 @media (min-width: 768px) and (max-width: 992px) {
	 /* .lead-group-add-modal body.active__minimal_sidebar .main__content_wrapper .main__content {
		 width: calc(100% - 110px);
	} */
	 .lead-group-add-modal .custom__tbody tr td {
		 padding: 6px 10px !important;
	}
	 .lead-group-add-modal .main__bottom {
		 height: 530px;
		 padding: 0 10px;
	}
	 .lead-group-add-modal .custom__thead tr th {
		 font-size: 16px;
		 padding: 7px 10px;
	}
	 .lead-group-add-modal .checkbox__text_2 {
		 font-size: 14px;
		 margin-right: 13px;
	}
	 .lead-group-add-modal .distribution__icon {
		 margin-right: 15px;
	}
	 .lead-group-add-modal .modal__content__bottom {
		 padding: 10px !important;
	}
	 .lead-group-add-modal .modal {
		 width: 72.2% !important;
	}
	 .lead-group-add-modal .modal__checkbox {
		 padding: 10px;
	}
	 .lead-group-add-modal .modal__select__text {
		 font-size: 16px !important;
		 margin-right: 5px;
	}
}
 @media (min-width: 768px) and (max-width: 992px) and (min-width: 768px) {
	 .lead-group-add-modal .main__bottom {
		 height: 644px;
	}
}
 