.wbh_payload_body{
    height: calc(100% - 35px);
}

.wbh_payload_body .awesome__scroll_bar{
    padding: unset;
}

.wbh_payload_body thead th {
    padding-left: 20px;
}

.wbh_payload_body td, th {
    padding: 10px 5px;
}

.wbh_payload_body ul.wbh_payload_body__type_list li.wbh_payload_body__type_item {
    color: #1C1B1F;
    display: flex;
    align-items: center;
    gap: 10px;
    font-weight: 300;
    font-size: 14px;
    line-height: 20px;
    cursor: pointer;
}

.wbh_payload_body ul.wbh_payload_body__type_list{
    display: flex;
    align-items: center;
    gap: 25px;
    margin-top: 0 !important;
    margin-bottom: 15px;
}

.wbh_payload_body .wbh_payload_body_none {
    text-align: center;
    border-top: var(--sh-border);
}

.wbh_payload_body .wbh_payload_body_none p {
    font-size: 12px;
    color: #546376;
    font-weight: 400;
    margin-top: 14px;
}