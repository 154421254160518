.wbh_url_handler{
    display: flex;
}

.wbh_select_method {
    width: 115px;
}

.wbh_url_field {
    width: 100%;
    display: flex;
    align-items: baseline;
    gap: 8px;
}

input.wbh_url_input {
    margin-right: 4px !important;
    border-radius: 0 5px 5px 0 !important;
    font-weight: 400;
}

.wbh_http_method_select_field {
    background-color: white;
    text-transform: uppercase;
    border: 1px solid #949db23d;
    border-radius: 5px 0 0 5px!important;
    display: flex;
    align-items: center;
    padding: 3px 10px;
    width: 85px;
    cursor: pointer;
    font-size: 14px;
    font-weight: 400;
    position: relative;
    height: 40px;
    white-space: nowrap;
    overflow: hidden;
    border-right: none !important;
    margin: 6px 0 0;
}

.clpb_icon{
    display: inline-flex;
    position: absolute;
    right: 2px;
}

.clpb_label {

}

.wbh_global_personalized .label_class_wrapper {
    height: 40px !important;
}

.error_message {
    color: red;
    font-size: 12px;
}

.wbh_url_input_wrapper {
    width: 100%;
}