.wbh_smart_web_hook_drawer_wrp {
    position: relative;
    height: 100vh;
}

.pdad_drawer_right_header {
    height: 70px;
    width: 100%;
    display: flex;
    justify-content: space-between;
    padding: 20px 24px;
    align-items: center;
    border-bottom: 1px solid #949db23d;
}

.pdad_drawer_right_header_title{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
}

.pdad_drawer_cancel_icon {
    width: 30px;
    height: 30px;
    display: flex;
    cursor: pointer;
    align-items: center;
    justify-content: center;
    background: var(--sh-gray-100);
    border-radius: 100px;
}

.pdad_drawer_cancel_icon:hover {
    transition: 0.3s ease;
}

.wbh_modal_default_body{
    height: calc(100vh - 145px);
    overflow-y: auto;
    overflow-x: hidden;
}

.wbh_mdb_content_wrp {
    display: flex;
    margin-bottom: 5px;
    width: 1200px;
}

.wbh_drawer_footer {
    width: 100%;
    position: absolute;
    right: 0;
    z-index: 99999;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    gap: 24px;
    padding: 14px 20px;
    background-color: white;
    border-top: 1px solid #949db23d;
}

.wbh_btn {
    position: relative;
    overflow: hidden;
    font-weight: 500;
    font-size: 13px;
    line-height: 20px;
    padding: 4px 8px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    transition: all 350ms ease-in-out;
}

.wbh_btn_cancel {
    color: #546376;
    background-color: transparent;
}

.wbh_btn_cancel:focus {
    color: #546376;
    background-color: transparent;
}

.wbh_btn_secondary {
    color: white;
    background-color: #006DF5;
}

.wbh_btn_secondary:focus {
    color: white !important;
    background-color: #006DF5 !important;
}

.wbh_btn_secondary:disabled {
    color: white;
    background-color: rgba(0, 109, 245, 0.24);
    cursor: not-allowed;
}

.wbh_btn_followup {
    display: flex;
    align-items: center;
    gap: 8px;
    padding: 11px 16px;
}

.wbh_btn:after {
    content: "";
    background: #90EE90;
    display: block;
    position: absolute;
    padding-top: 300%;
    padding-left: 350%;
    margin-left: -20px!important;
    margin-top: -120%;
    opacity: 0;
    transition: all 0.8s;
}

.wbh_btn:active:after {
    padding: 0;
    margin: 0;
    opacity: 1;
    transition: 0s;
}