.video_thumbnails.modal_thumbnail .manage_modal_card a{
    position: absolute;
    bottom: 15px;
    left: calc(50% - 80px);
}

.manage_modal_card p.template-title {
    position: absolute;
    top: 10px;
    left: 20px;
    color: white;
    background: #15151582;
}

.saved_reply_modal_wrapper .manage_modal_card p.template-title {
    width: calc(100% - 110px);
    padding: 0 5px;
    border-radius: 4px;
}
.saved_reply_modal_wrapper .video_thumbnails_scroll.awesome__scroll_bar {
    height: 100%;
    margin: 0;
}

.saved_reply_modal_wrapper .video_thumbnails {
    margin: 0;
}
.saved_reply_modal_wrapper .manage_modal_input_fields {
    padding-right: 0;
}
.saved_reply_modal_wrapper .search_input {
    margin-right: 10px;
}
.manage_modal_card p.template-type {
    font-weight: normal;
    font-size: 13.7254px;
    line-height: 24px;
    color: #FFFFFF;
    background: #133159;
    text-transform: capitalize;
    position: absolute;
    transform: rotate(45deg);
    top: 14px;
    right: -46px;
    padding: 3px 50px;
    box-shadow: 0 3px 10px -5px rgb(0 0 0);
}
.modal_thumbnail .manage_modal_card {
    background: #F6F7FB !important;
    border-radius: 5px;
    padding: 15px !important;
    position: relative;
    overflow: hidden;
    padding-bottom: 25px !important;
    height: 320px !important;
    margin: 0 0 20px 0 !important;
    border: 1px solid #ddd;
}

.search_input {
    position: relative;
    margin-left: auto;
    margin-right: 20px;
}
.search_input input {
    background: #FFFFFF;
    border: 1px solid rgba(19, 49, 89, 0.35) !important;
    box-sizing: border-box !important;
    border-radius: 5px !important;
    font-size: 16px !important;
    line-height: 22px;
    letter-spacing: 0.01em;
    color: rgba(19, 49, 89, 0.35);
    padding: 15px 57px 15px 20px !important;
    margin: 0px !important;
    height: 40px !important;
}
.search_input .search_icon {
    position: absolute;
    top: 5px;
    right: 9px;
    border-left: 1px solid rgba(19, 49, 89, 0.35);
    padding-left: 6px;
    height: 30px;
    padding-top: 5px;
    cursor: pointer;
}