.new_f_c_over_menu_item {
    display: flex;
    grid-gap: 8px;
    font-size: 14px;
    color: #546376;
    padding: 5px 10px;
    margin-bottom: 2px;
    border-radius: 4px;
    align-items: center;
    cursor: pointer;
    margin-left: 10px;
    margin-right: 10px;
}

.new_f_c_over_menu_item:hover {
    background-color: #949db21f;
}

.new_f_c_over_menu_item.active {
    background-color: #949db257;
}

.new_folder_c_menu_list {

}

.new_folder_c_over_container {
    margin-top: 10px;
}