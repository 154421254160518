body.active__minimal_sidebar .main__content_wrapper .main__content {
    /* margin-left: 100px !important; */
}
/* body {background: #F5F7F9;} */
/* marketing section start */
.main__content {width: 100%;}
.marketing_section {
    /* min-height: calc(100vh - 120px); */
    margin: 5px 0 20px;
    overflow: hidden;
    border-radius: 10px;
}
.marketing_section .page_heading {
    border-bottom: 2px solid #E5E8EF;
    justify-content: space-between;
    background: #fff;
    min-height: 90px;
    display: flex;
    flex-wrap: wrap;
    padding: 20px 30px;
    align-items: center;
}
.marketing_section .page_heading h4 {
    color: #133159;
    margin: 0;
    font-weight: 500;
    font-size: 24px;
    line-height: 36px;
}
.marketing_section .page_heading a {
    justify-content: center;
    background: #3C7EF3;
    align-items: center;
    border-radius: 5px;
    display: flex;
    min-width: 178px;
    height: 48px;
    color: #fff;
    font-weight: 500;
    font-size: 16px;
    line-height: 48px;
 }
.marketing_section .page_heading a svg {margin-right: 8px;}

.marketing_section .page_content {
    /* background-image: url(../images/working.svg);
    background-position: center 135px;
    background-repeat: no-repeat;
    background-size: auto;
    /* background-color: #fff; */
    /* position: relative; */
    /* min-height: 110vh; */
    /* margin-bottom: 20px; */
    /* padding-left: 9%; */
    /* border-radius: 0 0 10px 10px; */ 
}
.marketing_section .page_content .select_content {
    text-align: center;
    padding: 25px;
}
.marketing_section .page_content .select_content h4 {
    line-height: 30px;
    font-size: 24px;
    color: #133159;
    font-weight: 500;
}
.marketing_section .page_content .select_content p {
    color: rgba(19, 49, 89, 0.65);
    line-height: 26px;
    font-size: 16px;
    width: 465px;
    margin: 25px auto;
}
.marketing_section .page_content .select-wrapper {
    margin: 0 auto;
    width: 360px;
}
.marketing_section .page_content input.select-dropdown {
    border: 1px solid #E5E8EF !important;
    font-family: var(--poppins);
    background: #3C7EF3;
    border-radius: 5px;
    margin: 0;
    width: calc(100% - 20px);
    color: #fff;
    height: 60px;
    font-size: 20px;
    font-weight: 500;
    padding: 0 0 0 20px;
}
.marketing_section .dropdown-content {
    box-shadow: 0px 4px rgb(0 0 0 / 10%);
    border: 1px solid #E5E8EF;
    border-radius: 5px;
    position: relative;
}
.marketing_section .dropdown-content::after {
    border-top: 9px solid rgba(19, 49, 89, 0.65);
    border-bottom: 9px solid transparent;
    border-right: 8px solid transparent;
    border-left: 8px solid transparent;
    display: inline-block;
    height: 10px;
    content: '';
    top: 27px;
    right: 20px;
    position: absolute;
}
.marketing_section .select-dropdown.dropdown-content li.selected {
    border-bottom: 1px solid #E5E8EF;
    background: none;
}
.marketing_section .page_content .dropdown-content li>a,
.marketing_section .page_content .dropdown-content li>span {color: rgba(19, 49, 89, 0.65);}
.marketing_section .page_content .select-wrapper .caret {
    transform: scale(1.6);
    fill: #fff;
    right: 16px;
    z-index: 9999;
    font-size: 32px;
}
.marketing_section .select-dropdown li.disabled>span,
.marketing_section .select-dropdown li.disabled,
.marketing_section  .select-dropdown li.optgroup {
    color: rgba(19, 49, 89, 0.65);
    font-weight: 500;
    font-size: 20px;
    line-height: 36px;
}
.marketing_section .dropdown-content li {border-bottom: 1px solid #E5E5EF;}
.select-dropdown.dropdown-content li:hover {background-color: rgba(60, 126, 243,0.1) !important;}
.select-dropdown.dropdown-content li:hover span {color: #3C7EF3 !important;}
/* marketing section end */
.MuiAccordionDetails-root .row{
    margin-left: unset;
    margin-right: unset;
}
.category_select .input-field{
    background: #3C7EF3;
    border-radius: 5px;
    padding: 12px 15px;
    margin: unset;
    cursor: pointer;
    font-family: Poppins !important;
    font-style: normal !important;
    font-weight: 500 !important;
    font-size: 20px !important;
    line-height: 36px !important;
    color: #FFFFFF !important;
}
/* .category_select .input-field.Mui-focused{
    background: #FFFFFF;
    border: 1px solid #E5E8EF;
    box-sizing: border-box;
    border-radius: 5px;
    color: rgba(19, 49, 89, 0.65) !important;
}
.category_select .input-field.Mui-focused svg{
    color: rgba(19, 49, 89, 0.65) !important;
} */
.category_select .input-field .MuiSelect-select.MuiSelect-select{
    padding-right: 123px;
}
.category_select .input-field svg{
    color: #FFFFFF !important;
    width: 32px;
    height: 32px;
    top: 16px;
    right: 10px;
}
.category_select .input-field::before{
    display: none;
}
.category_select .input-field::after{
    display: none;
}
.page_heading .search_field .input-field::before{
    display: none;
}
.page_heading .search_field .input-field::after{
    display: none;
}
.page_heading .search_field .input-field{
    background: #F6F7FB;
    border: 1px solid #E5E8EF;
    box-sizing: border-box;
    border-radius: 5px;
    margin: unset;
    padding: 10px 15px;
    width: 220px;
    font-family: Poppins !important;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 24px;
    color: #133159;
}
.page_heading .search_field .input-field svg{
    color: #133159;
    right: 8px;
}
.loader .loader_content{
    display: flex;
    align-items: center;
    justify-content: center;
    top: 50%;
    position: fixed;
    right: calc(100% - 50%);
}
.no_data{
    
    font-family: 'Poppins' !important;
    font-style: normal;
    font-weight: 500;
    font-size: 24px;
    line-height: 36px;
    display: flex;
    align-items: center;
    letter-spacing: 0.01em;
    text-transform: capitalize;
    color: #133159;
    height: 100vh;
    justify-content: center;

}
@media screen and (max-width: 1366px) {
    .marketing_section .page_content .select_content h4,
    .marketing_section .page_heading h4 {letter-spacing: 1px;}
}
@media screen and (max-width: 1366px) {
    .marketing_section .page_content {
        background-position: center 165px;
        background-size: contain;
    }
}

a.back {
    width : 10%;
    justify-content: center;
    background: #F6F7FB;
    align-items: center;
    border-radius: 5px;
    display: flex;
    min-width: 115px;
    padding: 5px 12px;
    height: 50px;
    color: #3C7EF3;
    font-size: 16px;
    font-weight: 500;
    line-height: 50px;
    border: 1px solid #E5E8EF;
}

.template-page-loader {
    position: absolute;
    left: 50%;
    top: 50%;
}

@media(max-width:1440px){
    .marketing_section .page_heading  .font-18{
        font-size: 18px;
        }
    .campaign_template-working_img{
        margin:-70px 0 0 0 ;
    }
    .campaign_template-working_img svg{
        max-width: 60%;
        height: 100%;
    }
}