.marketing__messaging_service_section {
    min-height: calc(100vh - 120px);
    overflow: hidden;
    border-radius: 10px;
    background: #fff;
}

.marketing__messaging_service_section .messaging_services {
    background: var(--white);
    border-radius: 10px;
    margin-top: 15px;
}

.marketing__messaging_service_section .messaging_services .messaging_services_title {
    border-bottom: 1px solid rgba(60, 126, 243, 0.1);
}

.marketing__messaging_service_section .messaging_services .messaging_services_title h1 {
    font-weight: 500;
    font-size: 22px;
    line-height: 36px;
    letter-spacing: 0.01em;
    color: var(--dark_blue);
    margin: 0;
}

.marketing__messaging_service_section .back_button_to_setting_page a {
    padding: 8px 15px;
    border-radius: 5px;
    font-family: var(--poppins);
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    display: flex;
    align-items: center;
    letter-spacing: .01em;
    margin-bottom: 15px;
}

.marketing__messaging_service_section .messaging_services_body {
    padding: 20px;
}

.marketing__messaging_service_section .back_button_to_setting_page a,
.marketing__messaging_service_section .setting-table-props button{
    padding: 8px 15px;
    border-radius: 5px;
    font-family: var(--poppins);
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    display: flex;
    align-items: center;
    letter-spacing: .01em;
    margin-bottom: 15px;
}

.marketing__messaging_service_section .setting-table-props {
    display: flex;
    justify-content: space-between;
    margin-top: 25px;
}

/* Table design */
.marketing__messaging_service_section .cam__list__content_table{
    border: 1px solid rgb(225, 227, 234);
    background: #fff;
}

.marketing__messaging_service_section .cam__list__content_table .list__content_table__header{
    background: rgb(244, 244, 246);
}

.marketing__messaging_service_section .list__content_table__header th{
    box-sizing: border-box;
    font-size: 0.875rem;
    line-height: 1.25rem;
    font-weight: 600;
    padding: 0.75rem 1rem;
    position: relative;
    text-align: left;
    vertical-align: inherit;
    overflow-wrap: break-word;
}

.marketing__messaging_service_section .cam__list__contant__1st{
    width: 35%;
}

.marketing__messaging_service_section .cam__list__contant__2nd{
    width: 35%;
}

.marketing__messaging_service_section .cam__list__contant__3rd{
    width: 30%;
}

.marketing__messaging_service_section .messaging_list_each_service__empty td{
    text-align: center;
    font-size: 0.875rem;
    line-height: 1.25rem;
    color: rgb(18, 28, 45);
}

.marketing__messaging_service_section td{
    padding: 0.75rem 1rem;
}


/* Create messaging service */
.create_messaging_service_modal .create__message__service .field__label,
.ms__right_panel .create__message__service .field__label{
    color: rgb(18, 28, 45) !important;
    font-weight: 700 !important;
    font-size: 0.875rem !important;
    line-height: 1.25rem !important;
}

.create_messaging_service_modal .create__message__service .field__input input,
.ms__right_panel .create__message__service .field__input input{
    border: 1px solid #E5E8EF !important;
    box-sizing: border-box !important;
    border-radius: 5px !important;
    padding: 14px 16px !important;
    margin: 8px 0 0 0px !important;
    font-size: 16px !important;
    line-height: 24px;
    background: #fff;
    color: #8896A8;
}

.create_messaging_service_modal .create__message__service .messaging__identify__tag {
    margin: 0px;
    padding: 0px;
    color: rgb(18, 28, 45);
    font-size: 0.875rem;
    line-height: 1.5rem;
    font-weight: 400;
    margin-top: 16px;
}

.create_messaging_service_modal .create__message__service .MuiSelect-root,
.ms__right_panel .create__message__service .MuiSelect-root{
    border: 1px solid #ddd;
    border-radius: 4px;
    background: #fff;
    width: 100%;
    padding: 11px 16px 11px 16px !important;
    color: #8896A8;
}

#MarketingModule-container .ms__right_panel .create__message__service .MuiSelect-root {
    padding: 11px 16px 11px 16px !important;
}

.create_messaging_service_modal .create__message__service .MuiInputBase-root,
.ms__right_panel .create__message__service .MuiInputBase-root{
    width: 100%;
}

.create_messaging_service_modal .create__message__service .field_dropdown,
.ms__right_panel .create__message__service .field_dropdown{
    margin-top: 8px;
}

.messaging_service_list .messaging_list_each_service .messaging_service_link {
    color: #039be5;
    text-decoration: underline;
    cursor: pointer;
}


/* Edit page */
.messaging_services_body .messaging_service_edit_page {
    display: flex;
}

.messaging_service_edit_page .ms__left_panel {
    width: 20%;
    padding-left: 30px;
    padding-top: 20px;
    background: #eee;
    height: calc(100vh - 220px);
}

.ms__edit_link_holders {
    margin-top: 25px;
    margin-left: 30px;
}

.ms__edit_link_holders p {
    color: #039be5;
    font-weight: 300;
    margin: 10px 0;
    cursor: pointer;
}

.ms__edit_link_holders p.active {
    font-weight: 400;
}

.ms__edit_link_holders p:hover {
    text-decoration: underline;
}

.messaging_service_edit_page .ms__right_panel {
    margin: 0 10px;
    padding: 14px;
    width: 90%;
    height: 100%;
}

.messaging_service_edit_page .ms__right_panel .create__message__service {
    border: 1px solid #ccc;
    border-radius: 10px;
    padding: 25px;
}

.messaging_service_edit_page .field__label.ms__sid {
    display: flex;
    align-items: center;
    font-weight: 500 !important;
    font-size: 16px !important;
    margin-top: 7px;
}

.messaging_service_edit_page .field__label.ms__sid svg {
    margin-left: 8px;
    color: #039be5;
    cursor: pointer;
}

.messaging_service_edit_page .ms__form_buttons {
    padding: 10px;
    margin-top: 10px;
}

.messaging_service_edit_page .ms__form_buttons button {
    box-sizing: border-box;
    width: auto;
    display: inline-block;
    border: none;
    outline: none;
    transition: background-color 100ms ease-in 0s, box-shadow 100ms ease-in 0s, color 100ms ease-in 0s;
    font-weight: 600;
    text-decoration: none;
    position: relative;
    margin: 0px;
    cursor: pointer;
    color: rgb(255, 255, 255);
    padding: 0.5rem 0.75rem;
    border-radius: 4px;
    font-size: 0.875rem;
    line-height: 1.25rem;
    margin-right: 8px;
}

.messaging_service_edit_page .ms__form_buttons .ms__save_button {
    background: none rgb(2, 99, 224);
    box-shadow: rgb(2, 99, 224) 0px 0px 0px 1px;
}

.messaging_service_edit_page .ms__form_buttons .ms__save_button:hover {
    background: none rgb(8, 42, 144);
}

.messaging_service_edit_page .ms__form_buttons .ms__save_button:disabled {
    background: none rgb(153, 205, 255);
    box-shadow: rgb(153 205 255) 0px 0px 0px 1px;
    cursor: default;
}

.messaging_service_edit_page .ms__form_buttons .ms__reset_button {
    color: rgb(18, 28, 45);
    box-shadow: rgb(136 145 170) 0px 0px 0px 1px;
    background: none rgb(255, 255, 255);
}

.messaging_service_edit_page .ms__form_buttons .ms__reset_button:hover {
    background: none rgb(200, 195, 195);
}

.messaging_service_edit_page .ms__form_buttons .ms__reset_button:disabled {
    color: rgb(174, 178, 193);
    box-shadow: rgb(202 205 216) 0px 0px 0px 1px;
    background: none rgb(255, 255, 255);
    cursor: default;
}

.messaging_service_edit_page .ms__form_buttons .ms__delete_button {
    color: var(--red);
    cursor: pointer;
}

.messaging_service_edit_page .ms__form_buttons .ms__delete_button:hover {
    text-decoration: underline;
}

.messaging_service_edit_page .ms__form_buttons .ms__saving_circular {
    width: 20px !important;
    height: 20px !important;
    margin-left: 5px;
}

.messaging_service_list .ms__delete_single_number {
    background-color: var(--red);
    color: #fff;
}

.messaging_service_list .ms__delete_single_number:hover {
    background-color: #c51a36;
    color: #fff;
}

.ms__sender_pool_wrapper .ms__add_senders {
    background: none rgb(2, 99, 224);
    color: #fff;
}

.ms__sender_pool_wrapper .ms__add_senders:hover {
    background: none rgb(10, 66, 139);
    color: #fff;
}