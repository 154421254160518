.wbh_headers_panel_item{
    display: flex;
    align-items: center;
    gap: 8px;
}

.wbh_headers_panel_item:not(:last-child){
    margin-bottom: 12px;
}

.sh_hp_remove{
    background: var(--sh-warning-light);
    border-radius: 50%;
    cursor: pointer;
    width: 24px;
    height: 24px;
    margin-top: 5px;
}

.wbh_headers_panel_item > input {
    flex: 1;
    padding: 10px 15px !important;
    margin: 0 !important;
    border-radius: 5px !important;
}

.wbh_content_border_wrp{
    position: relative;
    height: 100%;
    border: var(--sh-border);
    border-radius: 4px;
}

.wbh_headers_panel_content{
    position: relative;
    padding: 12px;
}