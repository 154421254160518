.MarketingModule-container-parent-class .direct__maill_v2_campaign_setting_preview{
    display: flex;
    gap: 10px;
    margin: 10px 0px;
}
.MarketingModule-container-parent-class .direct__maill_v2_campaign_setting_preview .dmvcsp_template_preview,
.MarketingModule-container-parent-class .direct__maill_v2_campaign_setting_preview .dmvcsp_image_preview{
    box-shadow: 0 0 2px #133159;
    border-radius: 4px;
    display: flex;
}
.MarketingModule-container-parent-class .direct__maill_v2_campaign_setting_preview .dmvcsp_image_preview img{
    border-radius: 4px;
}