.campaigns_list_content.update_campaign_list {
    /* height: calc(100vh - 306px) !important; */
    padding: 0 8px !important;
    margin: 20px 0;
}
.campaigns_list_content.update_campaign_list .campaigns_list_item:first-child{
    margin-top: 0 !important;
}
.MarketingModule-container-parent-class .folder_create_new_video_wr{
    border: 1px solid var(--accent);
    border-radius: 30px;
    font-size: 12px;
    padding: 2px 7px;
    display: flex;
    align-items: center;
    grid-gap: 4px;
    cursor: pointer;
}
.MarketingModule-container-parent-class .folder_create_new_video_wr svg {
    width: 13px;
    height: 15px;
}
.folder_video_container_update_v2{
    padding: 16px 8px;
    text-align: center;
}
.folder_video_container_update_v2 .folder_video_paragraph_update_v2{
    font-size: 12px;
    color: var(--red);
    font-weight: 400;
    text-align: left;
    padding-left: 5px;
}
.folder_video_container_update_v2 .folder_video_paragraph_update_v2 span{
    font-weight: 600;
    
}