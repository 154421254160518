.mainWrp{
  max-width: 785px;
  background: #fff;
  width: 100%;
  border-radius: 4px;
}
.mainWrp:focus{
  outline: none;
}
.headerWrp{
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 2px solid #ddd;
  margin-bottom: 24px;
  padding: 18px 20px 18px 18px;
  background: rgba(250, 250, 250, 1);
  border-top-right-radius: 4px;
  border-top-left-radius: 4px;
}
.headerLeft{
  display: flex;
  grid-gap: 8px;
}
.headerClose{
  height: 30px;
  width: 30px;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  background: #FFDAD9;
  border-radius: 50%;
  cursor: pointer;
}
.headerClose svg path{
  fill: #ed003980
}
.actionListLoading{
  min-height: 450px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 14px;
}
.actionListLoadingText{
  min-height: 450px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 14px;
}
.mainContent{
  display: grid;
  grid-template-columns: 7fr 5fr;
  height: 450px;
  padding: 20px;
}
.mainContent .MuiSvgIcon-root{
  color: #546376 !important;
}
.mainContent .MuiButtonBase-root.Mui-checked .MuiSvgIcon-root{
  color: #6258FF !important;
}

.mainContentAccessDenied{
  display: grid;
  grid-template-columns: 7fr 5fr;
  height: 320px;
  padding: 20px;
}
.mainContentAccessDenied .MuiSvgIcon-root{
  color: #546376 !important;
}
.mainContentAccessDenied .MuiButtonBase-root.Mui-checked .MuiSvgIcon-root{
  color: #6258FF !important;
}

.actionBodyTitle{
  font-size: 18px;
  font-weight: 500;
  letter-spacing: 0.15px;
  line-height: 28px;
  color: #1C1B1F;
  margin: 0;
}

.accessDeniedBodyTitle{
  font-size: 18px;
  font-weight: 500;
  letter-spacing: 0.15px;
  line-height: 28px;
  color: #1C1B1F;
  margin-top: 10px;
  margin-bottom: 20px;
}

.progressWrp{
  background: #6258ff0f;
  padding: 20px;
  border-radius: 4px;
  display: inline-block;
  margin: 28px 0 20px 0;
  width: 100%;
  max-width: 280px;
}
.progressWrpTitle{
  font-size: 14px;
  font-weight: 500;
  letter-spacing: 0.15px;
  line-height: 20px;
  color: #546376;
}
.progressLineWrp{
  display: flex;
  justify-content: space-between;
  grid-gap: 12px;
}
.progressBar{
  position: relative;
  width: 100%;
}
.progressBackLine{
  position: absolute;
  width: 100%;
  height: 4px;
  left: 0;
  top: 9px;
  background: #ddd;
  z-index: 1;
}
.progressFrontLine{
  position: absolute;
  width: 100%;
  height: 4px;
  left: 0;
  top: 9px;
  background: #9747FF;
  z-index: 2;
  width: 50%;
}
.progressCount{
  flex-shrink: 0;
  font-size: 14px;
  font-weight: 500;
  color: #546376;
}
.groupItemWrp + .groupItemWrp{
  padding: 10px 0 0 0;
}
.groupItemWrpAlt{
  position: relative;
}
.groupItemWrpAlt .actionListWrp{
  padding: 0 0 0 53px;
}
.groupItemWrpAlt .actionListWrp li{
  font-size: var(--helper-text-font-size);
  font-weight: var(--helper-text-font-weight);
  letter-spacing: var(--helper-text-font-letter-spacing);
  line-height: var(--helper-text-font-line-height);
  color: var(--text-primary-color);
  list-style: disc;
  padding: 6px 0 0 0;
 
}
.groupItemWrpAlt .actionListWrp li span{
  display: flex;
  align-items: center;
}
.actionListWrp .groupItemCheckList + .groupItemCheckList{
  padding: 16px 0 0 0;
}
.groupItemCheckList{
  display: flex;
  align-items: center;
  grid-gap: 20px;
}
.groupItemCheckList span{
  display: inline-flex;
  font-size: var(--body2-font-size);
  font-weight: var(--body2-font-weight);
  letter-spacing: var(--body2-font-letter-spacing);
  line-height: var(--body2-font-line-height);
  color: var(--text-primary-color);
}
.groupItemCheckList .groupItemArrow{
  display: flex;
  align-items: center;
  grid-gap: 5px;
  cursor: pointer;
}
.actionListWrp .listDoneLi,
.groupItemCheckList .listCompleted{
  text-decoration: line-through;
  color: #546376a8 !important;
  cursor: unset !important;
}
.actionListWrp li{
  cursor: pointer;
}
.startWrp{
  margin-top: 40px;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 5px;
  justify-content: center;
}
.startText{
  color: #1C1B1F;
  font-size: 14px;
  font-weight: 600;
  letter-spacing: 0.5px;
}
.startTextConfirm{
  color: #546376a8;
  font-size: 12px;
  font-weight: 500;
  letter-spacing: 0.3px;
  text-align: justify;
}
.startButton{
  padding: 10px 20px;
  background: #133159;
  cursor: pointer;
  color: #ffffff;
  font-size: 14px;
  border-radius: 4px;
  margin-top: 10px;
}