.marketing_resent_failed_contact_module .mrfcm_resent_action_container{
    background: #e3ebfa;
    margin-bottom: 10px;
    padding: 12px;
    border-radius: 5px;
}
.marketing_resent_failed_contact_module .mrfcm_resent_action_container .mrfcm_rac_title{
    font-size: 16px;
    font-weight: 700;
    color: var(--dark_blue);
}
.marketing_resent_failed_contact_module .mrfcm_resent_action_container .mrfcm_action_container{
    margin-top: 10px;
}
.marketing_resent_failed_contact_module .mrfcm_resent_action_container .mrfcm_ac_each_action{
    display: flex;
    gap: 10px;
    margin-bottom: 5px;
}
.marketing_resent_failed_contact_module .mrfcm_resent_action_container .mrfcm_ac_each_action .mrfcm_ac_ea_icon{
    cursor: pointer;
}
.marketing_resent_failed_contact_module .mrfcm_ac_each_action .mrfcm_ac_ea_info_container{
    display: flex;
    flex-direction: column;
}
.marketing_resent_failed_contact_module .mrfcm_ac_each_action .mrfcm_ac_ea_info_container .mrfcm_ac_ea_des{
    font-size: 13px;
    margin-left: 14px;
    font-weight: 300;
    color: #adb7c5;
}
.marketing_resent_failed_contact_module .mrfcm_hints{

}
.marketing_resent_failed_contact_module .mrfcm_button_container{
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 20px;
}
.marketing_resent_failed_contact_module .mrfcm_button_container .mrfcm_bc_submit_button {
    display: flex;
    padding: 10px 20px;
    background: #3c7ef3;
    border-radius: 6px;
    cursor: pointer;
    color: #fff;
    align-items: center;
    justify-content: center;
    align-items: center;
    text-align: center;
}
.marketing_resent_failed_contact_module .mrfcm_button_container .mrfcm_bc_submit_button .mrfcm_bc_sb_icon{
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 10px;
}
.marketing_resent_failed_contact_module .mrfcm_button_container .mrfcm_bc_submit_button .mrfcm_bc_sb_icon{
    display: flex;
}

.globalModalWrapper.campaign-setting-stats-contact{
    max-width: 1000px !important;
    width: 1000px !important;
}

/* for custom pagination */
.marketing_resent_failed_contact_module .resent_failed_table_pagination .resent_failed_table_pagi_row{
    display: flex;
    justify-content: center;
    align-items: center;
    grid-gap: 10px;
}
.marketing_resent_failed_contact_module .resent_failed_table_pagination .rftp_back_button,
.marketing_resent_failed_contact_module .resent_failed_table_pagination .rftp_next_button,
.marketing_resent_failed_contact_module .resent_failed_table_pagination .rftp_input_field{
    width: 40px;
    height: 40px;
    display: inline-flex;
    border: 1px solid rgba(98,88,255,0.5);
    align-items: center;
    justify-content: center;
    border-radius: 4px;
    padding: 8px;
    margin: 0 !important;
}
.marketing_resent_failed_contact_module .resent_failed_table_pagination .rftp_input_field:focus{
    box-shadow: none !important;
    border-color: rgba(98,88,255,0.5) !important;
}
.marketing_resent_failed_contact_module .resent_failed_table_pagination .rftp_input_field_total{
    width: 40px;
    height: 40px;
    display: inline-flex;
    border: 1px solid rgba(148,157,178,.24);
    align-items: center;
    justify-content: center;
    border-radius: 4px;
    padding: 8px;
    margin: 0 !important;
}
.marketing_resent_failed_contact_module .resent_failed_table_pagination .rftp_divider{
    position: relative;
    margin: 0 10px;
    color: rgba(148,157,178,.24);
}
.marketing_resent_failed_contact_module .resent_failed_table_pagination .rftp_divider:after{
    position: absolute;
    content: "";
    top: -14px;
    left: 0;
    width: 1px;
    background: #ddd;
    height: 30px;
    -webkit-transform: rotate(25deg);
    transform: rotate(25deg);
}
.marketing_resent_failed_contact_module .resent_failed_table_pagination .rftp_back_button,
.marketing_resent_failed_contact_module .resent_failed_table_pagination .rftp_next_button{
    cursor: pointer;
}
.marketing_resent_failed_contact_module .resent_failed_table_pagination .rftp_bb_disabled{
    opacity: .5;
    cursor: not-allowed!important;
}
