.campaign_folder__main__wrapper{
    width: 100%;
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(240px, 1fr));
    gap: 5px;
}
.cfmw__each__folder__wrapper{
    height: 50px;
    background-color: #f5f7f9;
    border-radius: 6px;
    display: flex;
    cursor: pointer;
    border: 1px solid #ddd;
    /* box-shadow: 0px 8px 10px rgb(0 0 0 / 12%); */
    position: relative;
    align-items: center;
    padding: 2px 10px;
}
.cfmw__each__folder__wrapper .cfmw_efw_details{
    overflow: hidden;
}
.cfmw__each__folder__wrapper .cfmw_efw_details .cfmw_efw_d_title{}
.cfmw_efw_icon{
    display: inline-flex;
    margin-right: 15px;
}
.selected_folder{
    cursor: not-allowed;
    background: #3c7ef32b;
    color: #fff;
}
.default__folder {
    background-color: blue !important;
}
.cfmw_efw_d_title{
    font-size: 13px;
    color: rgba(19, 49, 89, 0.5);
    font-weight: 700;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    display: block;
}
.cfmw_efw_d_count_w{
    font-size: 12px;
    display: flex;
    gap: 10px;
    /* color: #b0aafc; */
    color: rgba(19, 49, 89, 0.5);
}
.cfmw_efw_edit{
    position: absolute;
    top: 0;
    right: 2px;
    z-index: 9;
    display: none;
}
.cfmw__each__folder__wrapper:hover .cfmw_efw_edit{
    display: block;
}
.cfmw__each__folder__wrapper.cfmw_efw__add__folder{
    background-color: #ffffff;
    border: 2px dashed;
    justify-content: center;
}
.campaign_folder__main__wrapper_loading{
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100px;
    background: #fff;
    border-radius: 14px;
}
.campaign_folder__main__wrapper_loading .cfmwl__wrapper{
    justify-content: center;
    align-items: center;
    display: flex;
}

/* for drag and drop */