.add_leadflow_modal_new {
    top: 0;
    bottom: 0;
    background: #F6F7FB;
    border-radius: 10px 0px 0px 10px;
    max-width: 900px;
    width: 100%;
    position: fixed;
    right: 0;
    left: unset; 
    padding: 0;
    overflow-y: auto;
    overflow-x: hidden;
    will-change: top, opacity;
}

.add_leadflow_modal_new:focus {
    outline: none;
}
.add_leadflow_modal_new .contact_source_container{
    min-height: 200px;
}

.add_leadflow_modal_new .top_content {
    border-bottom: 1px solid #E7EFF0;
}
.add_leadflow_modal_new .sub_title h2 {
    font-size: 24px;
    line-height: 30px;
    font-weight: 500;
    text-transform: capitalize;
    letter-spacing: 0.03em;
    color: var(--dark_blue);
    margin: 0;
}
.add_leadflow_modal_new .cross_button {
    background: var(--red);
    width: 26px;
    height: 26px;
    border-radius: 50%;
    cursor: pointer;
}
.add_leadflow_modal_new .modal__buttons{
    padding: 30px;
    border-top: 1px solid #E7EFF0;
}
.add_leadflow_modal_new .add__group__btn, .modal__close__btn{
    font-size: 14px;
    line-height: 30px;
    height: 50px;
    font-weight: 500;
    letter-spacing: 0.01em;
    color: var(--white);
    display: flex;
    align-items: center;
    padding: 10px 20px;
    border-radius: 10px;
    background-color: #2F80ED;
    border: none;
    cursor: pointer;
}

.add_leadflow_modal_new .modal__close__btn {
    background-color: var(--red);
    margin-left: 20px;
}
.add_leadflow_modal_new .input_field_group{
  padding: 20px 10px 0px 15px;
}
.add_leadflow_modal_new .lead_src_select input, 
.add_leadflow_modal_new .title_input_field input{
    background: #FFFFFF  !important;
    border: 1px solid rgba(19, 49, 89, 0.35) !important;
    box-sizing: border-box !important;
    border-radius: 5px !important;
    padding: 24px 20px !important;
    font-weight: normal;
    font-size: 16px !important;
    line-height: 22px;
    letter-spacing: 0.01em;
    color: rgba(19, 49, 89, 0.35);
}
.add_leadflow_modal_new .title_input_field input:focus{
    border-bottom: 1px solid rgba(19, 49, 89, 0.35) !important; 
    box-shadow: unset !important;
}
.add_leadflow_modal_new .lead_src_select label,
.add_leadflow_modal_new .title_input_field label{
    font-weight: normal;
    font-size: 16px;
    line-height: 30px;
    color: #133159;
}
.add_leadflow_modal_new .lead_src_select .MuiInput-underline:before{
  display: none !important;
}
.add_leadflow_modal_new .lead_src_select .MuiInput-underline:after{
  display: none !important;
}
.add_leadflow_modal_new .lead_src_select .MuiInputBase-root{
  display: block !important;
}
.add_leadflow_modal_new .lead_src_select .MuiSelect-select{
  background: #FFFFFF !important;
  border: 1px solid rgba(19, 49, 89, 0.35) !important;
  box-sizing: border-box !important;
  border-radius: 5px !important;
  padding: 13px 20px !important;
  font-weight: normal;
  font-size: 16px !important;
  line-height: 22px;
  letter-spacing: 0.01em;
  color: rgba(19, 49, 89, 0.35);
}


.add_leadflow_modal_new .lead_src_select .check_icon,
.title_input_field span{
    color: red !important;
}
.add_leadflow_modal_new .lead_src_select svg{
  z-index: 1 !important;
  fill: rgba(84, 99, 118, 1)!important;
}
/* ================LeadFlow__setting__css================ */
.add_leadflow_modal_new .rule_top{
    border: 1px solid #E5E8EF;
    padding: 10px 20px;
}
.add_leadflow_modal_new .title_icon{
  height: 20px;
}
.add_leadflow_modal_new .title{
    font-weight: 500;
    font-size: 16px;
    line-height: 30px;
    color: #133159;
    display: flex;
    align-items: center;
    gap: 5px;
}
.add_leadflow_modal_new .delete_btn button{
    border: unset;
    cursor: pointer;
    background: #F6F7FB;
    border-radius: 5px;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    color: #FF264A;
    padding: 8px 21.5px;
}
.add_leadflow_modal_new .delete_btn_icon{
    margin-right: 10px;
}

.add_leadflow_modal_new .create_new_leadflow_btn{
  margin-right: 15px;
}
.add_leadflow_modal_new .create_new_leadflow_btn:last-child{
  margin-right: unset;
}
.add_leadflow_modal_new .add_rule_btn a{
    background: #F6F7FB;
    font-size: 16px;
    line-height: 30px;
    text-align: center;
    color: #3C7EF3;
    font-weight: normal;
    padding: 5px 30px;
    display: flex;
    align-items: center;
}
.add_leadflow_modal_new .add_rule_top_content{
    border-bottom: 1px solid #E5E8EF;
    padding: 20px;
}
.add_leadflow_modal_new .add_rule_content{
    background: white;
    margin: 20px 0px;
    border-radius: 10px;
}
.add_leadflow_modal_new .assign_content{
    padding: 20px;
}
.add_leadflow_modal_new .assign_to{
  margin-right: 50px;
}
.add_leadflow_modal_new .assing_btns a,
.add_leadflow_modal_new .assing_btns button{
    background: #3C7EF3;
    border-radius: 5px;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: 0.01em;
    color: #FFFFFF;
    border: unset !important;
    cursor: pointer;
    padding: 8px 14px !important;
}
.add_leadflow_modal_new .assing_btns_text{
    font-size: 16px;
    line-height: 30px;
    color: #133159;
    margin-right: 15px;
}
.add_leadflow_modal_new .drp_select{
    margin: 0px 14px;
}
.add_leadflow_modal_new .rule_input_content{
    padding: 20px;
}
.add_leadflow_modal_new .rule_inputs{
  align-items: center;
}

.add_leadflow_modal_new .drp_select input,
.add_leadflow_modal_new .rule_inputs input{
    background: #E5E8EF !important;
    border: 1px solid #ADB7C5 !important;
    box-sizing: border-box !important;
    border-radius: 5px !important;
    padding: 10px 15px !important;
    margin-top: 18px !important;
    font-weight: normal;
    font-size: 16px !important;
    line-height: 22px;
    letter-spacing: 0.01em;
    color: rgba(19, 49, 89, 0.35);
}
.add_leadflow_modal_new .drp_select input:focus,
.add_leadflow_modal_new .rule_inputs input:focus {
    border-bottom: 1px solid rgba(19, 49, 89, 0.35) !important;
    box-shadow: unset !important;
}
.add_leadflow_modal_new .drp_select input{
    width: 88px !important;
}
.add_leadflow_modal_new .drp_select svg,
.add_leadflow_modal_new .rule_inputs svg{
    z-index: 1 !important;
    fill: rgba(19, 49, 89, 0.65) !important;
    cursor: pointer;
    right: 8px !important;
}


.add_leadflow_modal_new .single_contact_source {
    min-height: 150px;
    display: block;
    border: none;
    border-radius: 5px;
    background: none;
    box-shadow: 0 0 15px 1px rgba(69, 65, 78, 0.2);
    cursor: pointer;
    padding: 0px 5px 5px 5px;
}
.add_leadflow_modal_new .single_contact_source .contact_source_img{
    width: 100%;
    height: 100%;
    padding: 0px 5px 0px 5px;
}


.add_leadflow_modal_new .single_contact_source.active {
    border: 2px solid #2f80ed;
}
.add_leadflow_modal_new .single_contact_source:focus {
    background: none;
}

.add_leadflow_modal_new .list__title {
    word-break: break-word;
}
.add_leadflow_modal_new .category_title {
    padding:10px;
    margin-bottom:10px;
    margin-Top:10px;
    border-radius:5px;
}


