/* for drag items */
.meis_pype__drag__each__item__v3{
    display: flex;
    gap: 10px;
    align-items: center;
}
.meis_pype__drag__each__item__v3 .meis_drag__container{
    display: flex;
    /* padding: 2px; */
    padding: 2px 12px;
    /* border: 1px dashed #ddd; */
    border-radius: 4px;
    cursor: grabbing;
    gap: 15px;
    height: 50px;
    align-items: center;
}
.meis_pype__drag__each__item__v3 .meis_drag__icon__v2{
    display: flex;
}
.meis_pype__drag__each__item__v3 .meis_drag__icon__v2 svg{
    display: inline-flex !important;
}
.meis_pype__drag__each__item__v3 .meis_drag__container_title{
    display: none;
}
.meis_pype__drag__each__item__v3 .meis_each__item__title__v2{
    display: block !important;
}
.meis_pype__drag__each__item__v3 .meis_drag__container.meis_active_drag {
    background-color: aquamarine;
}
.meis_pype__drag__each__item__v3 .meis_each__item__title__v2.meis_active_drag{
    display: none !important;
}
.meis_pype__drag__each__item__v3 .meis_drag__container.meis_active_drag .meis_drag__container_title{
    display: block !important;
}

/* for drop items */
.meis__pype_drop__each__container.meis_active_drag_over{
    background-color: aqua !important;
    border: 2px dashed rgb(16, 125, 125) !important;
}