.custom__pype__drawer__v1{
    width: 650px;
    padding: 20px 0px;
}
.custom__pype__drawer__v1 .drawer_empty_container{
    width: 100%;
    height: calc(100vh - 40px);
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
}
.custom__pype__drawer__v1 .cfamw_body{
    padding: 16px 20px;
    height: calc(100vh - 155px);
}
.custom__pype__drawer__v1 .cfamw_body .cfamw_b_input{
    margin: 0 !important;
    padding: 0 12px !important;
    border: 1px solid #ddd !important;
    border-radius: 6px !important;
    box-shadow: unset !important;
    box-sizing: border-box !important;
    resize: none;
}
.custom__pype__drawer__v1 .cfamw_body .cfamw_b_title_wrapper{
    margin-bottom: 8px;
}
.custom__pype__drawer__v1 .cfamw_body .cfamw_b_textarea{
    margin: 0 !important;
    padding: 12px !important;
    border: 1px solid #ddd !important;
    border-radius: 6px !important;
    box-shadow: unset !important;
    box-sizing: border-box !important;
    resize: none;
    height: 80px;
}
.custom__pype__drawer__v1 .cfamw_body .cfamw_b_textarea:focus{
    outline: none;
}
.custom__pype__drawer__v1 .cfamw_body .cfamw_b_input_label{
    font-size: 14px;
    color: var(--dark_blue);
    margin-bottom:6px;
    display: inline-block;
}

.custom__pype__drawer__v1 .campaign_folder__add__main__wrapper .cfamw_header{
    display: flex;
    justify-content: space-between;
    grid-gap: 12px;
    border-bottom: 1px solid #ddd;
    padding: 0 20px 20px 20px;
}
.custom__pype__drawer__v1 .cfamw_header .cfamw_h_title{
    font-family: var(--poppins);
    font-size: 24px;
    line-height: 36px;
    font-weight: 500;
    margin: 0;
}
.custom__pype__drawer__v1 .cfamw_header .cfamw_h_button{
    background-color: var(--red);
    width: 25px;
    height: 25px;
    border-radius: 50px;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
}
.custom__pype__drawer__v1 .each_contact_item_inbox_upgrade{
    position: relative;    
}
.custom__pype__drawer__v1 span.check__box__select__by__content {
    position: absolute;
    right: 10px;
    top: 5px;
    background: var(--gray_dark);
    /* width: 30px; */
    /* height: 30px; */
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 10px 20px;
    border-radius: 6px;
    cursor: pointer;
    opacity: 0;
    transition: all linear 0.3s;
}
.custom__pype__drawer__v1 .each_contact_item_inbox_upgrade:hover .check__box__select__by__content{
    opacity: 1;
}

.custom__pype__drawer__v1 .each_contact_item__na {
    font-size: 13px;
    font-style: italic;
}
#custom_global_drawer_pypepro .MuiPaper-root.MuiDrawer-paper.MuiDrawer-paperAnchorRight{
    border-radius: 10px 0px 0px 10px !important;
}
.inbox__v2__global__custom__width__700 {
    width: 700px !important;
}
.inbox__v2__global__custom__width__800 {
    width: 800px !important;
}
.inbox__v2__global__custom__width__900 {
    width: 900px !important;
}

.inbox___upgrade___drawer__contact___content .inbox___upgrade___drawer__header__wr{
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 2px solid var(--gray_dark);
    padding: 0 20px;
    height: 70px;

}
.inbox___upgrade___drawer__contact___content .inbox___upgrade___drawer__header__wr .inbox___upgrade___drawer__header__title{
    font-family: var(--poppins);
    font-size: 24px;
    line-height: 36px;
    font-weight: 500;
    margin: 0;
    color: var(--dark_blue);

}
.inbox___upgrade___drawer__contact___content .inbox___upgrade___drawer__header__wr .inbox___upgrade___drawer__header__close svg{
    background-color: var(--red);
    width: 25px;
    height: 25px;
    border-radius: 50px;
    cursor: pointer;
}

#custom_global_drawer_pypepro.video__record__container .MuiPaper-root.MuiDrawer-paper.MuiDrawer-paperAnchorRight{
    width: 554px !important;
    height: 311px !important;
    border-radius: 8px !important;
}
.video__record__container .custom__pype__drawer__v1{
    width: 100% !important;
    padding: 0px !important;
    height: 100% !important;
}
#custom_global_drawer_pypepro.video__record__container .MuiPaper-root.MuiDrawer-paper.MuiDrawer-paperAnchorRight.MuiPaper-elevation16{
    right: 10px !important;
    top: 10px !important;
}



/* update */
.custom__pype__drawer__v1 .cfamw_body .cfamw_b_icon_color_preview_wrapper{
    display: flex;
    grid-gap: 12px;
}
.custom__pype__drawer__v1 .cfamw_body .cfamw_b_icpw_icon_wrapper {
    flex: 1;
}
.custom__pype__drawer__v1 .cfamw_body .cfamw_b_icpw_color_preview_wrapper {
    flex: 1;
}
.custom__pype__drawer__v1 .cfamw_body  .cfamw_b_icpw_iw_icons {
    display: flex;
    flex-wrap: wrap;
    grid-gap: 6px;
    border: 1px solid #ddd;
    padding: 14px;
    border-radius: 4px;
    height: calc(100% - 25px);
    align-content: flex-start;
}
.custom__pype__drawer__v1 .cfamw_body  .cfamw_b_icpw_iw_icons .cfamw_b_icpw_iw_icons_item{
    height: 28px;
    width: 28px;
    border: 1px solid #dddd;
    border-radius: 4px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
}
.custom__pype__drawer__v1 .cfamw_body .cfamw_b_icpw_iw_colors{
    display: grid;
    grid-template-columns: repeat( auto-fill, minmax(30px, 1fr) );
    grid-gap: 6px;
    border: 1px solid #ddd;
    padding: 14px;
    border-radius: 4px;
}
.custom__pype__drawer__v1 .cfamw_body .cfamw_b_icpw_iw_colors .cfamw_b_icpw_iw_colors_item{
    /* width: 30px; */
    height: 30px;
    border: 1px solid #EEEEEE;
    border-radius: 4px;
    cursor: pointer;
}
.custom__pype__drawer__v1 .cfamw_body .cfamw_b_icpw_cpw_preview_wrapper{
    padding-top: 10px;
}

.custom__pype__drawer__v1 .cfamw_footer{
    border-top: 1px solid #ddd;
    padding: 20px 20px 0 20px;
    display: flex;
    justify-content: center;
    grid-gap: 12px;
}
.custom__pype__drawer__v1 .cfamw_footer .cfamw_footer_delete_folder{
    color: var(--red);
    background: rgba(28, 27, 31, 0.12);
    font-size: 14px;
    padding: 8px 12px;
    border-radius: 4px;
    cursor: pointer;
}
.custom__pype__drawer__v1 .cfamw_footer .cfamw_footer_add_folder{
    color: #fff;
    background: var(--dark_blue);
    font-size: 14px;
    padding: 8px 12px;
    border-radius: 4px;
    cursor: pointer;
}
.custom__pype__drawer__v1 .cfamw_body .cfmw__each__folder__wrapper{
    width: 259px;
}
.custom__pype__drawer__v1 .cfamw_body  .cfamw_b_icpw_iw_icons .cfamw_b_icpw_iw_icons_item.active_selectd_icon{
    border: 2px solid #756b6b;
}
.custom__pype__drawer__v1 .cfamw_body  .cfamw_b_icpw_iw_colors .cfamw_b_icpw_iw_colors_item.active_selectd_color{
    border: 2px solid #756b6b;
}
@media(max-width: 992px){
    #custom_global_drawer_pypepro .MuiPaper-root.MuiDrawer-paper::-webkit-scrollbar{
        width: 6px;
    }
}