.custom__pype__drawer__v1{
    width: 650px;
    padding: 20px 10px;
}
.custom__pype__drawer__v1 .drawer_empty_container{
    width: 100%;
    height: calc(100vh - 40px);
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
}
.custom__pype__drawer__v1 .each_contact_item_inbox_upgrade{
    position: relative;    
}
.custom__pype__drawer__v1 span.check__box__select__by__content {
    position: absolute;
    right: 10px;
    top: 5px;
    background: var(--gray_dark);
    /* width: 30px; */
    /* height: 30px; */
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 10px 20px;
    border-radius: 6px;
    cursor: pointer;
    opacity: 0;
    transition: all linear 0.3s;
}
.custom__pype__drawer__v1 .each_contact_item_inbox_upgrade:hover .check__box__select__by__content{
    opacity: 1;
}

.custom__pype__drawer__v1 .each_contact_item__na {
    font-size: 13px;
    font-style: italic;
}
#custom_global_drawer_pypepro .MuiPaper-root.MuiDrawer-paper.MuiDrawer-paperAnchorRight{
    border-radius: 10px 0px 0px 10px !important;
}
.inbox__v2__global__custom__width__700 {
    width: 700px !important;
}
.inbox__v2__global__custom__width__800 {
    width: 800px !important;
}
.inbox__v2__global__custom__width__900 {
    width: 900px !important;
}

.inbox___upgrade___drawer__contact___content .inbox___upgrade___drawer__header__wr{
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 2px solid var(--gray_dark);
    padding: 0 20px;
    height: 70px;

}
.inbox___upgrade___drawer__contact___content .inbox___upgrade___drawer__header__wr .inbox___upgrade___drawer__header__title{
    font-family: var(--poppins);
    font-size: 24px;
    line-height: 36px;
    font-weight: 500;
    margin: 0;
    color: var(--dark_blue);

}
.inbox___upgrade___drawer__contact___content .inbox___upgrade___drawer__header__wr .inbox___upgrade___drawer__header__close svg{
    background-color: var(--red);
    width: 25px;
    height: 25px;
    border-radius: 50px;
    cursor: pointer;
}

#custom_global_drawer_pypepro.video__record__container .MuiPaper-root.MuiDrawer-paper.MuiDrawer-paperAnchorRight{
    width: 554px !important;
    height: 311px !important;
    border-radius: 8px !important;
}
.video__record__container .custom__pype__drawer__v1{
    width: 100% !important;
    padding: 0px !important;
    height: 100% !important;
}
#custom_global_drawer_pypepro.video__record__container .MuiPaper-root.MuiDrawer-paper.MuiDrawer-paperAnchorRight.MuiPaper-elevation16{
    right: 10px !important;
    top: 10px !important;
}