.MuiPaper-root .MuiButtonBase-root.menuitem__li__display__block{
    display: block !important;
}
.menuitem__li__display__block .each__item__des{
    font-size: 12px;
}
.MarketingModule-container-parent-class .campaign__registration__container__inner{
    display: flex;
    grid-gap: 16px;
}
.MarketingModule-container-parent-class .campaign__registration__left__single__span{
    display: flex;
    justify-content: space-between;
    font-weight: 400;
    color: rgb(18, 28, 45);
    font-size: 0.875rem;
    line-height: 1.25rem;
}
.MarketingModule-container-parent-class .campaign__registration__left__single__span.left__campaign__regActive{
    color: rgb(2, 99, 224);
    font-weight: 700;
    text-decoration: underline;
    cursor: pointer;
}
.MarketingModule-container-parent-class .campaign__registration__left__single__span.left__campaign__step__done{
    color: rgb(2, 99, 224);
    font-weight: 400;
    text-decoration: none;
    cursor: pointer;
}

.MarketingModule-container-parent-class .campaign__registration__left__single__span svg{
    width: 17px;
}
.MarketingModule-container-parent-class .campaign__registration__left__single__span .check__campaign__reg svg{
    color: rgb(14, 124, 58);
}
.MarketingModule-container-parent-class .campaign__registration__left__single__span .warning__campaign__reg svg{
    color: rgb(96, 107, 133);
}

.MarketingModule-container-parent-class .campaign__registration__container__left{
    width: 100%;
    max-width: 300px;
    padding: 0 13px;
}
.MarketingModule-container-parent-class .campaign__registration__container__right{
    flex: 1 auto;
    border: 1px solid #ddd;
    padding: 24px;
    margin-bottom: 24px;
}
.MarketingModule-container-parent-class .campaign__registration__container__right .extra__bottom__label{
    color: rgb(18, 28, 45);
    font-size: 0.875rem;
    line-height: 1.5rem;
    font-weight: 400;
}
.MarketingModule-container-parent-class .campaign__registration__container__right .seu__up__list__h4{
    margin: 0px 0px 1.25rem;
    padding: 0px;
    color: rgb(18, 28, 45);
    font-size: 1.5rem;
    line-height: 2rem;
    font-weight: 700;
    display: block;
}
.MarketingModule-container-parent-class .campaign__registration__container__right .seu__up__list__h5{
    font-size: 0.875rem;
    font-weight: 700;
    line-height: 1.25rem;
    color: rgb(18, 28, 45);
    margin: 24px 0 0 0;
}
.MarketingModule-container-parent-class .campaign__registration__container__right span.each__selected{
    background: #ddd;
    border-radius: 30px;
    padding: 2px 7px;
    margin-right: 4px;
}
.MarketingModule-container-parent-class .campaign__registration__container__right .campaign__select__radio__2nd{
    font-size: 0.875rem;
    line-height: 1.25rem;
    font-weight: 500;
}
.MarketingModule-container-parent-class .campaign__registration__container__right .seu__up__list__p1{
    margin: 0px 0px 1.5rem;
    padding: 0px;
    color: rgb(18, 28, 45);
    font-size: 0.875rem;
    line-height: 1.5rem;
    font-weight: 400;
}
.MarketingModule-container-parent-class .campaign__registration__container__right .seu__up__list__p2{
    margin: 0px;
    padding: 0px;
    color: rgb(18, 28, 45);
    font-size: 0.875rem;
    line-height: 1.5rem;
    font-weight: 400;
}
.MarketingModule-container-parent-class .campaign__registration__container__right .agree_to_add_vn{
    font-size: 0.875rem;
    line-height: 1.25rem;
    font-weight: 500;
    display: flex;
    grid-gap: 5px;
    margin: 16px 0;
}
.MarketingModule-container-parent-class .campaign__registration__container__v2 .campaign__reg__con__v2__title{
    margin: 0 0 4px 0;
    padding: 0px;
    color: rgb(18, 28, 45);
    font-size: 2rem;
    line-height: 2.75rem;
    font-weight: 700;
    display: block;
}
.MarketingModule-container-parent-class .campaign__select__radio__wr {
    margin-bottom: 1.25rem;
}

.MarketingModule-container-parent-class .campaign__select__radio__wr .campaign__select__radio__single{
    display: flex;
    align-items: center;
    grid-gap: 5px;
    margin-top: 0.75rem;
}
.MarketingModule-container-parent-class .campaign__select__radio__wr .campaign__select__radio__single span{
    display: inline-flex;
    cursor: pointer;
}
.MarketingModule-container-parent-class .campaign__registration__container__right .active__radio__campaign{
    color:rgb(2, 99, 224)
}
.MarketingModule-container-parent-class .campaign__registration__container__right .deactive__radio__campaign{
    color: rgb(136, 145, 170);
}
.MarketingModule-container-parent-class .campaign__registration__container__right .field__input textarea{
    border: 1px solid #E5E8EF !important;
    box-sizing: border-box !important;
    border-radius: 5px !important;
    padding: 14px 16px !important;
    margin-top: 8px !important;
    font-size: 16px !important;
    line-height: 24px;
    background: #fff;
    color: #8896A8;
    height: 66px !important;
    resize: none;
    width: 100% !important;
}
.MarketingModule-container-parent-class .campaign__registration__container__right .field__input input{
    border: 1px solid #E5E8EF !important;
    box-sizing: border-box !important;
    border-radius: 5px !important;
    padding: 14px 16px !important;
    margin: 8px 0 0 0px !important;
    font-size: 16px !important;
    line-height: 24px;
    background: #fff;
    color: #8896A8;
}
.MarketingModule-container-parent-class .campaign__registration__container__right .field__content{
    margin-bottom: 10px;
}
.MarketingModule-container-parent-class .campaign__registration__container__right .campaign__registration__count__v2{
    font-weight: 500 !important;
    font-size: 14px !important;
    line-height: 18px !important;
    color: var(--dark_blue) !important;
}
.MarketingModule-container-parent-class .campaign__registration__container__right .field__label{
    color: rgb(18, 28, 45) !important;
    font-weight: 700 !important;
    font-size: 0.875rem !important;
    line-height: 1.25rem !important;
    
}
.MarketingModule-container-parent-class .campaign__registration__container__right .messaging__identify__tag{
    margin: 0px;
    padding: 0px;
    color: rgb(18, 28, 45);
    font-size: 0.875rem;
    line-height: 1.5rem;
    font-weight: 400;
    margin-top: 16px;
}
.MarketingModule-container-parent-class .campaign__registration__container__right .MuiInputBase-root{
    width: 100%;
}
#MarketingModule-container .campaign__registration__container__right .MuiSelect-root{
    width: 100%;
    border: 1px solid #ddd;
    border-radius: 4px;
    background: #fff;
    width: 100%;
    padding: 11px 16px 11px 16px !important;
    color: #8896A8;
}
.MarketingModule-container-parent-class .campaign__registration__container__right .field_dropdown{
    margin-top: 8px;
}
.MarketingModule-container-parent-class .campaign__registration__container__right .field__input__checkbox{
    display: flex;
    grid-gap: 5px;
    color: rgb(18, 28, 45);
    font-size: 0.875rem;
    line-height: 1.5rem;
    font-weight: 400;
}
.MarketingModule-container-parent-class .campaign__registration__container__right .step__4__checkbox{
    margin-top: 8px;
}
.MarketingModule-container-parent-class .campaign__registration__container__right .field__input textarea:focus{
    outline: 0;
}
.MarketingModule-container-parent-class .campaign__registration__container__right .campaign__registration__form__btn__wrapper{
    display: flex;
    align-items:center;
    grid-gap: 16px;
}
.MarketingModule-container-parent-class .campaign__registration__container__right .campaign__registration__form__btn{
    background: #3c7ef3;
    /* width: 100%;
    max-width: 150px; */
    display: flex;
    justify-content: center;
    align-items: center;
    height: 40px;
    padding: 0 16px;
    border-radius: 4px;
    color: #fff;
    cursor: pointer;
}

.MarketingModule-container-parent-class .campaign__registration__container__right .cam__list__content_table{
    border: 1px solid rgb(225, 227, 234);
    background: #fff;

}

.MarketingModule-container-parent-class .campaign__registration__container__right .cam__list__content_table .list__content_table__header{
    background: rgb(244, 244, 246);
}

.MarketingModule-container-parent-class .campaign__registration__container__right .list__content_table__header th{
    box-sizing: border-box;
    font-size: 0.875rem;
    line-height: 1.25rem;
    font-weight: 600;
    padding: 0.75rem 1rem;
    position: relative;
    text-align: left;
    vertical-align: inherit;
    overflow-wrap: break-word;
}
.MarketingModule-container-parent-class .campaign__registration__container__right .messaging_list_each_service__empty{
    width: 100%;
    align-items: center;
    justify-content: center;
    padding: 30px 0;
}
.MarketingModule-container-parent-class .campaign__registration__container__right .messaging_list_each_service td{
    font-size: 0.875rem;
    line-height: 1.25rem;
    color: rgb(18, 28, 45);
    padding: 0.75rem 1rem;
}
.MarketingModule-container-parent-class .campaign__registration__container__right .messaging__service__for__checkbox{}
.MarketingModule-container-parent-class .campaign__registration__container__right .messaging__service__for__underline{
    color: rgb(2, 99, 224) !important;
    text-decoration: underline;
}
.MarketingModule-container-parent-class .campaign__registration__container__right .not__registered{
    display: inline-flex;
    align-items: center;
    grid-gap: 4px;
}
.MarketingModule-container-parent-class .campaign__registration__container__right  td.messaging__service__for__checkbox {
    display: inline-flex;
}
.MarketingModule-container-parent-class .campaign__registration__container__right  td.messaging__service__for__checkbox svg{
    cursor: pointer;
}
.MarketingModule-container-parent-class .campaign__registration__container__right .not__registered svg{
    width: 16px;
    margin-top: -3px;
    color: #f00;
}
.MarketingModule-container-parent-class .campaign__registration__container__right .not__registered span{
    display: inline-flex;
}
.MarketingModule-container-parent-class .campaign__registration__container__right .messaging_service_list{
    margin-bottom: 24px;
}
.MarketingModule-container-parent-class .campaign__registration__container__right .cam__list__contant__1st{
    width: 10%;
}
.MarketingModule-container-parent-class .campaign__registration__container__right .cam__list__contant__2nd{
    width: 30%;
}
.MarketingModule-container-parent-class .campaign__registration__container__right .cam__list__contant__3rd{
    width: 30%;
}
.MarketingModule-container-parent-class .campaign__registration__container__right .cam__list__contant__4th{
    width: 30%;
}
.MarketingModule-container-parent-class .campaign__registration__container__right .messaging_list_each_service__empty td{
    text-align: center;
    font-size: 0.875rem;
    line-height: 1.25rem;
    color: rgb(18, 28, 45);
}