:root {
    /* Colors */
    --sh-secondary-main: #006df5;
    --sh-gray: #F5F5F5;
    --sh-other-outlined-border: #949db23d;
    --sh-warning-light: #FFDCC3;
    --sh-gray-100: #F5F5F5;

    /* Text */
    --sh-text-primary: #1c1b1f;
    --sh-text-secondary: #546376;
    --sh-text-disabled: #546376a8;

    /* Border */
    --sh-border: 1px solid var(--sh-other-outlined-border);

    --sh-border-radius-4: 4px;
}

.wbh_overwrite_container.MuiDrawer-paper {
    max-width: unset !important;
}

.wbh_overwrite_container .MuiPaper-root {
    background: unset !important;
}

.wbh_overwrite_container .raw_body_personalized .label_class_wrapper{
    height: 35px !important;
    background-color: #fff;
}

.wbh_main_wrapper .invalid {
    text-decoration: underline wavy #d52c2c 1px;
    color: #d52c2c !important;
}

.wbh_output_preview_wrapper .json_preview_wbh {
    color: #2a2a20;
}

.wbh_output_preview_wrapper .json_preview_wbh .key {
    color: #2a2a20;
}

.wbh_output_preview_wrapper .json_preview_wbh .string {
    color: #c27048;
}

.wbh_output_preview_wrapper .json_preview_wbh .number {
    color: #bd84cf;
}

.select_followup_buttons {
    margin-top: 20px;
}