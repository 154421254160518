.wbh_payload_body_form_data_textarea textarea {
    height: 100%;
    padding: 10px;
    font-size: 12px;
    font-weight: 400;
}

.wbh_payload_body_form_data_textarea {
    position: relative;
}

.wbh_payload_body_form_data_textarea textarea {
    border: var(--sh-border);
    border-radius: 4px;
    color: #133159;
    overflow: auto;
    outline: none;
    box-shadow: none;
    resize: none;
}

.wbh_raw_body_ps {
    width: 135px;
    position: absolute;
    top: 15px;
    right: 20px;
}