/* pagination css */
/* .campaign-contact-add-modal .pagination_bar__update__v2,
.MarketingModule-container-parent-class .pagination_bar__update__v2
{
	display: flex;
    align-items: center;
    justify-content: center;
}
.campaign-contact-add-modal .pagination_bar__ul__v2,
.MarketingModule-container-parent-class .pagination_bar__ul__v2
{
	max-width: 300px;
} */

 .pagination_bar_stat_contact .stat-contact-pagination-text input
{
	border: 2px solid #3c7ef3;
	width: 70px;
	height: 48px;
	border-radius: 4px;
    padding: 5px;
	margin-left: 12px;
	margin-bottom: 0 !important;
}
/* .campaign-contact-add-modal .pagination_bar__ul__v2 a,
.MarketingModule-container-parent-class .pagination_bar__ul__v2 a
{
	padding: 11px 13px;
}
.campaign-contact-add-modal .pagination__divider__v2,
.MarketingModule-container-parent-class .pagination__divider__v2
{
	font-weight: 500;
    font-size: 18px;
    line-height: 30px;
    color: #3c7ef3;
	margin-left: 5px;
}
.campaign-contact-add-modal .pagination__divider__total__v2,
.MarketingModule-container-parent-class .pagination__divider__total__v2{
	display: inline-block;
    padding: 2px 27px;
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 30px;
    color: #3c7ef3;
} */


.campaign-setting-stats-contact .pagination_bar_stat_contact ul{
    display: flex;
    justify-content: center;
    align-items: center;
}

.campaign-setting-stats-contact .pagination_bar_stat_contact li a{
    padding: 11px 13px;
    border-radius: 5px;
}

.campaign-setting-stats-contact .search_button_stat_contact {
    width: 50%;
    float: right;
    margin-bottom: 20px;
}