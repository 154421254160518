.wbh_main_wrapper {
    color: #133159;
    flex: 1;
    border-right: 1px solid rgba(0,0,0,0.12);
    position: relative;
    padding: 6px 15px 0 15px;
}

.wbh_main_wrapper input {
    border: 1px solid #e5e8ef !important;
    box-sizing: border-box !important;
    border-radius: 5px;
    line-height: 24px;
    color: #0c1038;
    margin: 6px 0 0 !important;
    height: auto;
    padding: 12px 18px!important;
    font-size: 14px;
    max-height: 40px;
}

.wbh_main_wrapper input:focus {
    box-shadow: unset !important;
}

.wbh_header{
    margin-block: 5px;
}

.wbh_title_wrapper {
    margin-bottom: 10px;
}

.wbh_payload_option{
    display: flex;
}

.wbh_payload_handler{
    margin-bottom: 15px;
}

.wbh_payload_item {
    color: #546376;
    text-align: center;
    flex: 1;
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    padding-block: 8px;
    cursor: pointer;
    transition: 350ms all ease-in-out;
    background: rgba(148, 157, 178, 0.12);
}

.wbh_payload_item:first-child{
    border-top-left-radius: 4px;
    border-bottom-left-radius: 4px;
}
.wbh_payload_item:last-child{
    border-top-right-radius: 4px;
    border-bottom-right-radius: 4px;
}

.active_tab {
    background-color: #006df5;
    color: white;
}

.wbh_payload_handler_body{
    overflow-y: auto;
}

.wbh_payload_handler_body input {
    font-weight: 400;
    font-size: 13px;
}

.error_message {
    color: red;
    font-size: 12px;
}