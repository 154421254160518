.wbh_payload_authorization {
    height: 100%;
}

.wbh_payload_auth_sections {
    display: flex;
    justify-content: space-between;
    border: var(--sh-border);
    border-radius: 4px;
    height: 100%;
}

.wbh_payload_auth_selector {
    width: 40%;
    border-right: 1px solid #e5d7d7;
}

.wbh_payload_auth_selector_type_label {
    margin-top: 16px;
    margin-left: 10px;
    font-size: 13px;
    font-weight: 400;
}

.wbh_payload_auth_selector_flex{
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.wbh_authorization_select_field{
    border: 1px solid #949db23d;
    border-radius: 4px;
    display: flex;
    align-items: center;
    padding: 3px 10px;
    width: 125px;
    cursor: pointer;
    font-size: 13px;
    font-weight: 400;
    position: relative;
    height: 36px;
    white-space: nowrap;
    overflow: hidden;
}

.clpb_label{

}

.clpb_icon{
    display: inline-flex;
    position: absolute;
    right: 2px;
}

.wbh_payload_auth_form {
    width: 60%;
}

.wbh_no_auth_none {
    text-align: center;
}

.wbh_no_auth_none p {
    font-size: 12px;
    color: #546376;
    font-weight: 400;
    margin-top: 14px;
}

.wbh_form_group_inline {
    display: flex;
    flex-direction: column;
    padding: 10px 10px 0 10px;
}

.wbh_form_group_inline input{
    height: 36px !important;
    margin-right: 12px !important;
    padding: 12px 5px!important;
    border-radius: 5px !important;
}

.wbh_form_group_inline span{
    font-size: 12px;
    font-weight: 400;
}

.wbh_authorization_bearer_token {

}

.wbh_authorization_basic_auth {

}

.mlvc_row_per_page_dropdown {
    margin-top: 16px;
    margin-right: 10px;
}