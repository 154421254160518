body.active__minimal_sidebar .main__content_wrapper .main__content {
    /* margin-left: 105px !important; */
    /* margin-top: 0; */
}
body {background: #F5F7F9;}
/* marketing section start */
.main__content {width: 100%;}
.marketing_section {
    /* min-height: calc(100vh - 120px); */
    margin: 5px 0 20px;
    overflow: hidden;
    border-radius: 10px;
}
.marketing_section .page_heading {
    justify-content: space-between;
    border-radius: 10px;
    margin-bottom: 20px;
    background: #fff;
    min-height: 90px;
    display: flex;
    padding: 20px 30px;
    align-items: center;
    margin-right: 30px;
}
.marketing_section .page_heading h4 span {color: #3C7EF3;}
.marketing_section .page_heading h4 {
    line-height: 36px;
    color: #133159;
    font-weight: 500;
    font-size: 24px;
    margin: 0 15px 0 0;
}
.marketing_section .page_heading .search_field {display: flex;justify-content: flex-end;}
.marketing_section .page_heading .search input {
    line-height: 50px;
    border: none;
    height: 50px;
    margin: 0;
    outline: none;
    font-size: 16px;
    padding-right: 8px;
}
.marketing_section .page_heading .search input:focus {
    box-shadow: none;
    outline: none;
    border: none;
}
.marketing_section .page_heading .select-wrapper {
    border-radius: 5px;
    width: 220px;
}
.marketing_section .page_heading .select-wrapper input.select-dropdown {
    border: 1px solid #E5E8EF !important;
    font-family: var(--poppins);
    width: calc(100% - 40px);
    background: #F6F7FB;
    margin: 0;
    padding: 0 20px;
    height: 50px;
    font-size: 16px;
    color: #133159;
    border-radius: 5px;
}
.marketing_section .page_heading .dropdown-content li>a,
.marketing_section .page_heading .dropdown-content li>span {color: rgba(19, 49, 89, 0.65);}
.marketing_section .page_heading .select-wrapper .caret {
    transform: scale(1.5);
    fill: #133159;
    right: 15px;
    z-index: 9999;
    font-size: 32px;
}
.marketing_section .page_heading .select-dropdown li.disabled>span,
.marketing_section .page_heading .select-dropdown li.disabled,
.marketing_section .page_heading .select-dropdown li.optgroup {
    color: rgba(19, 49, 89, 0.65);
    font-weight: 500;
    font-size: 16px;
}
.marketing_section .page_heading .search {
    background:#f6f7fb;
    border: 1px solid #E5E8EF;
    border-radius: 5px;
    min-width: 220px;
    margin: 0 35px;
    height: 50px;
    display: flex;
    padding: 0 20px;
    align-items: center;
}
.marketing_section .page_heading a {
    justify-content: center;
    background: #3C7EF3;
    align-items: center;
    border-radius: 5px;
    width: 200px;
    display: flex;
    height: 50px;
    color: #fff;
    font-weight: 500;
    font-size: 16px;
    line-height: 50px;
 }
.marketing_section .page_heading a svg {
    margin-right: 8px;
}
.marketing_section .page_content > .row {
    margin-bottom: 0;
}
.marketing_section .page_content {
    position: relative;
    /* height: calc(100vh - 170px); */
    /* margin-right: 30px; */
    box-sizing: border-box;
}
.marketing_section .page_content > .row {
    margin-right: -15px;
    margin-left: -15px;
}
.marketing_section .page_content .row .col {
    padding: 0 15px;
}
.marketing_section .marketing_card {
    border-radius: 10px;
    background: #fff;
    padding: 0 30px;
    margin-bottom: 30px;
    box-shadow: 1px 1px 0px 0px #dadada;
    /* height: 370px; */
}
.marketing_section .marketing_card .heading {
    border-bottom: 2px solid #E5E8EF;
    padding: 5px 0 0;
}
.marketing_section .marketing_card .heading h4 {
    padding-bottom: 5px;
    font-weight: 500;
    font-size: 20px;
    color: #133159;
    line-height: 30px;
}
.marketing_section .marketing_card .tags {
    justify-content: space-between;
    display: flex;
    margin: 15px 0 10px 0;
}
.marketing_section .marketing_card .tags p strong {
    line-height: 30px;
    /* font-size: 24px; */
    font-size: inherit;
    color: #3C7EF3;
    font-weight: 500;
}
.marketing_section .marketing_card .tags p {
    line-height: 24px;
    font-size: 16px;
    color: #060607;
}
.marketing_section .marketing_card .tags a {
    color: #3C7EF3;
}
.marketing_section .marketing_card .card_article p {
    line-height: 24px;
    font-weight: 400;
    font-size: 14px;
    color: #8896A8;
    height: 70px;
    overflow: hidden;
}
.marketing_section .marketing_card .card_article {
    min-height: 70px;
}
.marketing_section .marketing_card .card_action a:first-child {
    background: #F6F7FB;
    color: #6258FF;
}
.marketing_section .marketing_card .card_action {
    justify-content: center;
    align-items: center;
    display: flex;
    margin-left: -25px;
    margin-right: -25px;
}
.marketing_section .marketing_card .card_action a {
    border: 1px dashed #E5E8EF;
    border-radius: 10px;
    background: #FAFAFC;
    color: #3C7EF3;
    max-width: 200px;
    font-size: 16px;
    margin: 25px;
    display: flex;
    width: 100%;
    min-height: 110px;
    text-align: center;
    align-items: center;
    justify-content: center;
}
.marketing_section .marketing_card .card_action a svg {
    margin: 0 auto 12px;
    display: block;
}
.select-dropdown.dropdown-content li:hover {background-color: rgba(60, 126, 243,0.1) !important;}
.select-dropdown.dropdown-content li:hover span {color: #3C7EF3 !important;}
@media screen and (max-width: 1366px) {
    .marketing_section .page_heading .search {margin: 0 15px;}
    .marketing_section .page_heading {padding: 15px 24px;}
}
@media screen and (max-width: 991px) {
    .marketing_section .page_heading .select-wrapper {width: 200px;}
    .marketing_section .page_heading .search {
        min-width: auto;
        width: 158px;
    }
    .marketing_section .page_heading {flex-wrap: wrap;}
    .marketing_section .page_heading h4 {margin: 0 15px 15px 0;}
}
/* marketing section end */
