.MarketingModule-container-parent-class .empty___table___content{
    height: 200px;
    padding: 10px;
    color: red;
    justify-content: center;
    align-items: center;
    display: flex;
}
.MarketingModule-container-parent-class .contact___percentage .MuiLinearProgress-root.MuiLinearProgress-colorPrimary.MuiLinearProgress-determinate{
    width: 70px !important;
}

.MarketingModule-container-parent-class .campaign-contacts-stats-block{
    width: 100%;
    border: 1px dashed;
    padding-top: 10px;
    padding-left: 5px;
    padding-bottom: 10px;
    margin-top: 10px;
}

.MarketingModule-container-parent-class .campaign___stats___container .campaign___stats___body .main_content_container_button {
    padding: 13px 20px;
    background-color: var(--accent-color);
    border-radius: 5px;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: 0.01em;
    text-transform: capitalize;
    color: var(--accent-text-color) !important;
    cursor: pointer;
}

.MarketingModule-container-parent-class .campaign___stats___container .campaign___stats___body .main_content_container_button_icon {
    margin-right: 10px;
}

.MarketingModule-container-parent-class .campaign___stats___container .campaign___stats___body .main_content_topbar{
    margin-bottom: 40px;
}

.MarketingModule-container-parent-class .campaign_stats_lists_heading_alt {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-right: 20px;
}

.MarketingModule-container-parent-class .campaign_stats_lists_heading_bread__alt {
    display: flex;
}

.MarketingModule-container-parent-class .campaign_stats_bradcumb_container {
    margin-left: 15px;
}

.MarketingModule-container-parent-class .campaign_stats_lists_heading_bread__alt h3 {
  font-size: 20px;
}
.MarketingModule-container-parent-class .campaign_stats_bradcumb_container .campaign_stats_bradcumb_each {
  display: inline-flex;
  font-size: 16px;
  color: rgba(0, 0, 0, 0.54);
}
.MarketingModule-container-parent-class .campaign_stats_bradcumb_container
  .campaign_stats_bradcumb_each:hover {
  text-decoration: underline;
  cursor: pointer;
}
.MarketingModule-container-parent-class .campaign_stats_bradcumb_container
  .campaign_stats_bradcumb_each:last-child {
  color: rgba(0, 0, 0, 0.87);
  text-decoration: none !important;
}

.MarketingModule-container-parent-class .campaign_stats_bradcumb_container
  ul.campaign_stats_bradcumb_list {
  padding: 0;
  display: inline-flex;
  align-items: center;
  grid-gap: 5px;
}
.campaign__statictics__pagination_bar{
  display: flex;
  justify-content: center;
  align-items: center;
}
.campaign__statictics__pagination_bar .campaign__statictics__pagination_ul{
  max-width: 400px;
  width: 100%;
    display: flex;
    align-items: center;

}
.campaign__statictics__pagination_bar .campaign__statictics__pagination_ul a{
  padding: 11px 13px;
  color: #fff;
    background: #3c7ef3;
    border-radius: 5px;
    padding: 6px 13px;
    margin-left: 10px;
    display: block;
    border: 2px solid #3c7ef3;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
}

.campaign__statictics__pagination_ul .active input{
  border: 2px solid #3c7ef3;
    width: 70px;
    height: 48px;
    border-radius: 4px;
    padding: 5px;
    margin-left: 12px;
    margin-bottom: 0!important
}
.campaign__statictics__pagination_ul .pagination__divider__v3{
  font-weight: 500;
  font-size: 18px;
  line-height: 30px;
  color: #3c7ef3;
  margin-left: 5px;
}
.campaign__statictics__pagination_ul .pagination__total__v3{
  display: inline-block;
    padding: 2px 27px;
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 30px;
    color: #3c7ef3;
}
.search_button_campaign_stat_global{
  position: relative;
  display: flex;
  justify-content: flex-end;
  margin: 0 0 16px 0 ;
}
.search_button_campaign_stat_global input{
  max-width: 365px;
  width: 100%;
}



.stats_type_des {
  font-size: 20px;
  margin-bottom: 10px;
  color: #767676;
  display: flex;
  align-items: center;
}

span.stats_type_icon {
  display: flex;
  align-items: center;
  margin: 0 0 0 5px;
}

span.stats_type_text {
  margin-left: 8px;
}

.stats_schedule_des {
  margin-bottom: 8px;
}

.single___contact__timeline___container {
  width: calc( 100% - 40px );
}

.single___contact__timeline___container p.text-red {
  display: flex;
  align-items: center;
  margin-top: 6px !important;
}

.single___contact__timeline___container p.text-red svg {
  margin-left: 6px;
}

.campaign-contacts-stats-block {
  border-left: 4px solid #ddd;
  border-radius: 4px;
  padding: 15px 0;
  padding-left: 40px;
  position: relative;
}

.campaign-contacts-stats-block::before {
  content: '';
  position: absolute;
  bottom: 0;
  height: 20px;
  width: 20px;
  background: #ddd;
  display: block;
  border-radius: 50px;
  left: -12px;
  top: -12px;
}

.campaign-contacts-stats-block::after {
  content: '';
  position: absolute;
  bottom: 0;
  height: 3px;
  width: 100%;
  background: #ddd;
  display: block;
}

.stats_schedule_des,.stats_sent_des,.stats_status_des {
  font-size: 14px;
  color: #424242;
}

span.stats_type_text {
  color: #333;
}

.campaign-contacts-stats-block[data-status="success"]::before {
  background: green;
}

.campaign-contacts-stats-block[data-status="success"] {
  border-left-color: green;
}

.campaign-contacts-stats-block[data-status="error"]::before {
  background: #f00;
}

.campaign-contacts-stats-block[data-status="error"] {
  border-left-color: #f00;
}


.campaign-contacts-stats-block[data-status="warning"]::before {
  background: rgb(235, 112, 11);
}

.campaign-contacts-stats-block[data-status="warning"] {
  border-left-color: rgb(235, 112, 11);
}

.campaign-contacts-stats-block[data-status="yellow"]::before {
  background: rgb(231, 192, 15);
}

.campaign-contacts-stats-block[data-status="yellow"] {
  border-left-color: rgb(231, 192, 15);
}